<template>
  <div>
    <div class="card mt-3 mb-1">
      <div class="card-header mb-3">
        <div class="row align-items-left">
          <div class="col-md-6">
            <h4 class="mb-0 text-primary">
              {{ $t('manageToken.manageRoles.manageRoles') }}
            </h4>
          </div>
        </div>
      </div>
      <cp-tabs
        v-model="selectedTab"
        :tabs="tabs"
      />
      <keep-alive>
        <component
          :is="selectedTab"
          :submit-clicked="submitClicked"
          @step-submitted="handleSubmit($event)"
        />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CpTabs from '~/components/common/standalone-components/cp-tabs';
import CpInputContainer from '~/components/common/cpInputContainer';
import { CpInput, CpSelect } from '~/components/common/standalone-components/inputs';
import CpTabularHeaders from './common/tabular-headers';
import CpButton from '~/components/common/standalone-components/cp-button';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';
import CpMultiSigModal from './manage-roles-components/multi-sig-modal';
import CpManageRolesSettings from './manage-roles-tabs/manage-roles-settings';
import CpManageRolesMultiSig from './manage-roles-tabs/manage-role-multisig';

export default {
  name: 'CpManageRoles',
  components: {
    CpTabs,
    CpManageRolesSettings,
    CpManageRolesMultiSig,
    CpInput,
    CpInputContainer,
    CpSelect,
    CpButton,
    CpTabularHeaders,
    CpConfirmModal,
    CpMultiSigModal,
  },
  props: {
    submitClicked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      selectedTab: '',
    };
  },
  computed: {
    ...mapState('configToken', ['deploymentToken']),
    isAlgorandNetwork() {
      return this.deploymentToken.provider && this.deploymentToken.provider.includes('algorand');
    },
    inputTabSelected() {
      return this.selectedTab === this.tabs[0].value;
    },
    tabs() {
      return !this.isAlgorandNetwork ? [
        { text: this.$t('manageToken.manageRoles.manageRoles'), value: 'cp-manage-roles-settings' },
        { text: this.$t('manageToken.manageRoles.multisig'), value: 'cp-manage-roles-multi-sig' },
      ] : [
        { text: this.$t('manageToken.manageRoles.manageRoles'), value: 'cp-manage-roles-settings' },
      ];
    },
  },
  methods: {
    handleSubmit(success) {
      this.$emit('step-submitted', success);
    },
  },
};
</script>

<style scoped lang="scss">
.decimals {
  width: 70%;
}

.add-button {
  padding: 14px 85px;
  font-size: 16px;
  font-weight: 500;
  margin: 0 15px;
}

.role-input-row {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 12px;
  padding-right: 12px;
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
  &:nth-child(2) {
    border-top: 1px solid;
    border-color: #e5e5e5;
  }
}

.border-top-1 {
  border-top: 1px solid;
  border-color: #e5e5e5;
}

.remove-role-button {
  padding: 0;
  font-size: 24px;
  border: 0;
}
</style>
