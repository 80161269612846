<template>
  <div>
    <div class="card mt-3 mb-4">
      <div class="card-header mb-3">
        <div class="row align-items-left">
          <div class="col-md-6">
            <h4 class="mb-0 text-primary">
              {{ $t('manageToken.complianceRules.compliance') }}
            </h4>
          </div>
        </div>
      </div>
      <cp-tabs
        v-model="selectedTab"
        :tabs="tabs"
        class="mb-5"
      />
      <keep-alive>
        <component
          :is="selectedTab"
          :submit-clicked="submitClicked"
          @step-submitted="handleSubmit($event)"
        />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CpTabs from '~/components/common/standalone-components/cp-tabs';
import CpComplianceConfiguration from './compliance-rules-tabs/compliance-configuration';
import CpComplianceHistory from './compliance-rules-tabs/compliance-history';
import CpComplianceCounters from './compliance-rules-tabs/compliance-counters';

export default {
  name: 'CpTokenContract',
  components: {
    CpTabs,
    CpComplianceConfiguration,
    CpComplianceHistory,
    CpComplianceCounters,
  },
  props: {
    submitClicked: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedTab: '',
    };
  },

  computed: {
    ...mapState('configToken', ['deploymentToken']),
    inputTabSelected() {
      return this.selectedTab === this.tabs[0].value;
    },
    tabs() {
      const tabs = [
        { text: this.$t('manageToken.complianceRules.tabs.complianceConfiguration'), value: 'cp-compliance-configuration' },
        { text: this.$t('manageToken.complianceRules.tabs.complianceHistory'), value: 'cp-compliance-history' },
      ];
      if (this.deploymentToken.complianceType !== 'whitelisted') {
        tabs.push({ text: this.$t('manageToken.complianceRules.tabs.complianceCounters'), value: 'cp-compliance-counters' });
      }
      return tabs;
    },
  },

  methods: {
    handleSubmit(success) {
      this.$emit('step-submitted', success);
    },
  },
};
</script>
