import { render, staticRenderFns } from "./multi-sig-modal.vue?vue&type=template&id=38dcb248&scoped=true&"
import script from "./multi-sig-modal.vue?vue&type=script&lang=js&"
export * from "./multi-sig-modal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38dcb248",
  null
  
)

export default component.exports