<template>
  <div>
    <cp-table
      ref="countersTable"
      get-data-action="configToken/getComplianceCounters"
      :empty-table-message="emptyTableMessage"
      :fields="complianceCountersTable"
      :data-parser="parseComplianceHoldersCounters"
      :url-params="urlParams"
      without-search
      without-pagination
      class="table hide-hr"
    />
    <div class="d-flex flex-row justify-content-center pt-5 border-top-1">
      <cp-button
        size="md"
        custom-class="submit-button"
        @click="refreshComplianceCountersTable"
      >
        <i class="mr-1 ion ion-md-refresh-circle" />
        {{ $t('manageToken.complianceCounters.buttons.refresh') }}
      </cp-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Cookie from 'js-cookie';
import { i18nKeys, complianceCountersFields, complianceCountersRowLimit } from '../common/options';
import CpTable from '~/components/shared/cp-table';
import CpButton from '~/components/common/standalone-components/cp-button';

export default {
  name: 'CpComplianceCounters',
  components: {
    CpTable,
    CpButton,
  },
  data() {
    Cookie.set('perPage', complianceCountersRowLimit);
    return {
      complianceCountersTable: complianceCountersFields,
      urlParams: { deploymentId: this.$route.params.tokenId },
    };
  },
  computed: {
    ...mapState('configToken', ['deploymentToken']),
    emptyTableMessage() {
      const { status } = this.deploymentToken;
      if (status !== 'success') {
        return this.$t(i18nKeys.notDeployedToken);
      }
      return this.$t(i18nKeys.notAvailableComplianceCounters);
    },
  },
  methods: {
    ...mapActions('configToken', ['getComplianceCounters']),
    parseComplianceHoldersCounters(data) {
      const parsedData = [];
      if (data && data.length > 0) {
        const complianceHoldersCounters = data[0];
        const keys = Object.keys(complianceHoldersCounters);
        const countersWithOutCountries = [];
        const countersWithCountries = [];
        keys.map((key) => {
          const complianceCounters = complianceHoldersCounters[key];
          if (Array.isArray(complianceCounters)) {
            countersWithCountries.push(key);
          } else {
            countersWithOutCountries.push(key);
          }
          return key;
        });
        const counterWithOutCountriesToReturn = countersWithOutCountries.map(counter => ({
          complianceCounter: counter,
          count: complianceHoldersCounters[counter],
        }));
        counterWithOutCountriesToReturn.forEach(element => parsedData.push(element));
        const counterWithCountriesToReturn = countersWithCountries.map((counter) => {
          const countersCountriesArray = complianceHoldersCounters[counter];
          const countriesArray = countersCountriesArray.map(euRetailInvestors => ({
            complianceCounter: counter,
            count: euRetailInvestors.count,
            country: euRetailInvestors.countryCode,
          }));
          return countriesArray;
        });
        if (counterWithCountriesToReturn.length > 0) {
          counterWithCountriesToReturn[0].forEach(element => parsedData.push(element));
        }
      }
      return parsedData;
    },
    refreshComplianceCountersTable() {
      this.$refs.countersTable.updateTableData();
    },
  },
};
</script>

<style>
.hide-hr hr {
  display:none;
}
.table{
  table-layout: fixed;
}
.submit-button {
  padding: 14px 85px;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 24px;
}
</style>
