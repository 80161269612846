<template>
  <div class="d-flex flex-column step">
    <div class="step-header d-flex flex-row">
      <div class="step-number">
        <h5> {{ step.stepTitle }} </h5>
        <h6>{{ $t('manageToken.activateToken.deploymentLogs.step') }}{{ step.stepNumber }}</h6>
        <div v-if="step.status == 'Success'">
          <div
            v-for="(hash, index) in step.txHash"
            :key="index"
            class="txHash-container"
          >
            <h6 class="txHash-title">
              {{ $t('manageToken.activateToken.deploymentLogs.txHash') }}
            </h6>
            <h6 class="txHash">
              <a
                :href="showLinkToScanner(hash)"
                target="_blank"
              >{{ hash }}</a>
            </h6>
            <copyToClipboard
              v-if="hash"
              :link="hash"
            />
          </div>
        </div>
      </div>
      <div>
        <h6>{{ $t('manageToken.activateToken.deploymentLogs.initialDate') }}{{ step.initialDate }}</h6>
        <h6 v-if="step.status == 'Success'">
          {{ $t('manageToken.activateToken.deploymentLogs.finalDate') }}{{ step.finalDate }}
        </h6>
      </div>
      <div class="step-status">
        <h6 :class="stepStatusClass">
          {{ step.status }}
        </h6>
      </div>
      <a @click="triggerShowlogs">
        <img
          :src="arrowDown"
          :class="this.arrowDownClass"
        >
      </a>
    </div>
    <div
      v-if="showingLogs"
      class="step-body"
    >
      <div
        v-for="(log, index) in step.deploymentLogs"
        :key="index"
      >
        {{ log }}
      </div>
    </div>
  </div>
</template>
<script>
import copyToClipboard from '@/components/common/copy-to-clipboard';
import arrowDown from '@/assets/img/arrow-down.svg';

export default {
  name: 'ActivateStep',
  components: {
    copyToClipboard,
  },
  props: {
    step: {
      logs: {
        type: Array,
      },
      status: {
        type: String,
      },
      stepNumber: {
        type: Number,
      },
      initialDate: {
        type: String,
      },
      finalDate: {
        type: String,
      },
      stepTitle: {
        type: String,
      },
    },
    showLogs: {
      type: Boolean,
      default: false,
    },
    scannerLink: {
      type: String,
    },
    faucetLink: {
      type: String,
    },
  },
  data() {
    return {
      showingLogs: this.showLogs || this.step.status.toLowerCase() === 'failed',
      arrowDown,
    };
  },
  computed: {
    stepStatusClass() {
      return `status ${this.step.status.toLowerCase()}`;
    },
    arrowDownClass() {
      return `arrow-icon ${this.showingLogs ? 'display' : ''}`;
    },
  },
  watch: {
    showLogs(newVal) {
      this.showingLogs = newVal;
    },
  },
  methods: {
    triggerShowlogs() {
      this.showingLogs = !this.showingLogs;
    },
    showLinkToScanner(hash) {
      if (!this.scannerLink) { return null; }
      return `${this.scannerLink}tx/${hash}`;
    },
  },
};
</script>

<style scoped>
.step {
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 24px;
}

.step-header {
  justify-content: space-between;
}

.step-number {
  text-align: start;
  width: 50%;
}
.txHash-container{
  display: flex;
}
.txHash-title{
  white-space: nowrap;
}
.txHash{
  overflow: hidden;
  text-overflow: ellipsis;
}

h5 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  /* identical to box height, or 32px */

  letter-spacing: 0.15px;

  /* Text/Primary */

  color: #0A1828;
}

h6 {
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  /* identical to box height, or 20px */

  letter-spacing: 0.15px;
  color: #848B93;
}

.status {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;

}

.success {
  border: 1px solid #81DEE1;
  border-radius: 16px;

  color: #81DEE1;
}

.pending {
  border: 1px solid #B3B2B2;
  border-radius: 16px;

  color: #B3B2B2;
}

.processing {
  border: 1px solid #F5A01D;
  border-radius: 16px;

  color: #F5A01D;
}

.failed {
  border: 1px solid #F4374E;
  border-radius: 16px;

  color: #F4374E;
}

.skipped {
  border: 1px solid #15A3CF;
  color: #15A3CF;
  border-radius: 16px;
}

.step-body {
  width: 100%;
  text-align: start;
  padding-left: 5px;
  overflow-wrap: anywhere
}

.display {
  rotate: 180deg;
}

.arrow-icon {
  width: 12px;
}
</style>
