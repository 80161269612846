export const i18nKeys = {
  notDeployedToken: 'manageToken.complianceCounters.counterTableFields.notDeployedToken',
  notAvailableComplianceCounters: 'manageToken.complianceCounters.counterTableFields.notAvailableComplianceCounters',
};

export const complianceCountersFields = [
  { key: 'complianceCounter', i18nKey: 'manageToken.complianceCounters.counterTableFields.complianceCounter' },
  { key: 'country', i18nKey: 'manageToken.complianceCounters.counterTableFields.countryCode' },
  { key: 'count', i18nKey: 'manageToken.complianceCounters.counterTableFields.count' },
];

export const complianceCountersRowLimit = 50;
