<template>
  <div class="d-flex justify-content-between">
    <cp-token-step-card
      :step-title="$t('manageToken.steps.tokenContract.title')"
      :step-description="$t('manageToken.steps.tokenContract.description')"
      :step-status="tokenContractStepStatus"
      :step-index="0"
      :is-card-selected="isCardSelected(0)"
      :is-card-enabled="true"
      @change-step="handleStepChange(0)"
    />
    <cp-token-step-card
      :step-title="$t('manageToken.steps.compliance.title')"
      :step-description="$t('manageToken.steps.compliance.description')"
      :step-status="complianceStepStatus"
      :step-index="1"
      :is-card-selected="isCardSelected(1)"
      :is-card-enabled="isComplianceStepEnabled"
      @change-step="handleStepChange(1)"
    />
    <cp-token-step-card
      :step-title="$t('manageToken.steps.manageRoles.title')"
      :step-description="$t('manageToken.steps.manageRoles.description')"
      :step-status="permissionsStepStatus"
      :step-index="2"
      :is-card-selected="isCardSelected(2)"
      :is-card-enabled="isPermissionsStepEnabled"
      @change-step="handleStepChange(2)"
    />
    <cp-token-step-card
      :step-title="$t('manageToken.steps.activateToken.title')"
      :step-description="$t('manageToken.steps.activateToken.description')"
      :step-status="activationStepStatus"
      :step-index="3"
      :is-card-selected="isCardSelected(3)"
      :is-card-enabled="isActivationStepEnabled"
      @change-step="handleStepChange(3)"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import CpTokenStepCard from './token-step-card';

export default {
  name: 'CpTokenDeploymentSteps',
  components: {
    CpTokenStepCard,
  },
  props: {
    selectedStep: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      stepStatus: [],
    };
  },
  computed: {
    ...mapState('configToken', ['deploymentToken', 'deploymentExists']),
    ...mapGetters('configToken', [
      'hasCompliance',
      'hasOwners',
      'hasRoles',
      'tokenDeployed']),
    hasPermissions() {
      return this.hasRoles && this.hasOwners;
    },
    isTokenContractStepReady() {
      return this.deploymentExists;
    },
    tokenContractStepStatus() {
      return this.statusEnumToText(this.deploymentExists);
    },
    isComplianceStepEnabled() {
      if (!this.isAlgorandNetwork()) {
        if (this.deploymentToken.provider) {
        return this.tokenDeployed || this.isTokenContractStepReady || this.selectedStep >= 1;
        }
         // When creating a token for the first time, Vue state does not update its provider property, so it appears as undefined
          return this.selectedStep >= 1;
      }
      return false;
    },
    complianceStepStatus() {
      return this.statusEnumToText(this.hasCompliance());
    },
    isPermissionsStepEnabled() {
      return (
        this.tokenDeployed
        || this.hasCompliance()
        || this.selectedStep >= 2
        || !!(this.deploymentToken.roles && this.deploymentToken.roles.length > 0)
      );
    },
    permissionsStepStatus() {
      return this.statusEnumToText(this.hasPermissions);
    },
    activationStepStatus() {
      return this.statusEnumToText(false);
    },
    isActivationStepEnabled() {
      return this.tokenDeployed || this.hasPermissions || this.selectedStep === 3;
    },
  },
  watch: {
    selectedStep() {
      this.$forceUpdate();
    },
  },
  methods: {
    ...mapActions('configToken', ['selectStep']),
    statusEnumToText(readyCondition) {
      let statusEnum = 'pending';
      if (this.tokenDeployed) {
        statusEnum = 'done';
      } else if (readyCondition) {
        statusEnum = 'ready';
      }
      return this.$t(`manageToken.status.${statusEnum}`);
    },
    handleStepChange(index) {
      this.$emit('step-changed', index);
    },
    isCardSelected(index) {
      return this.selectedStep === index;
    },
    isAlgorandNetwork() {
      return !!(this.deploymentToken.provider && this.deploymentToken.provider.includes('algorand'));
    },
  },
};
</script>
