export const ComplianceRulesStandard = [
  {
    variable: 'totalInvestorsLimit',
    type: 'number',
    value: 0,
    comment: '',
  },
  {
    variable: 'minUSTokens',
    type: 'number',
    value: 0,
    comment: '',
  },
  {
    variable: 'minEUTokens',
    type: 'number',
    value: 0,
    comment: '',
  },
  {
    variable: 'usInvestorsLimit',
    type: 'number',
    value: 0,
    comment: '',
  },
  {
    variable: 'usAccreditedInvestorsLimit',
    type: 'number',
    value: 0,
    comment: '',
  },
  {
    variable: 'nonAccreditedInvestorsLimit',
    type: 'number',
    value: 0,
    comment: '',
  },
  {
    variable: 'maxUSInvestorsPercentage',
    type: 'number',
    value: 0,
    comment: '',
  },
  {
    variable: 'blockFlowbackEndTime',
    type: 'date',
    value: '',
    comment: '',
  },
  {
    variable: 'nonUSLockPeriod',
    type: 'number',
    value: 7760000,
    comment: '90 Days',
  },
  {
    variable: 'minimumTotalInvestors',
    type: 'number',
    value: 0,
    comment: '',
  },
  {
    variable: 'minimumHoldingsPerInvestor',
    type: 'number',
    value: 0,
    comment: '',
  },
  {
    variable: 'maximumHoldingsPerInvestor',
    type: 'number',
    value: 0,
    comment: '',
  },
  {
    variable: 'euRetailInvestorsLimit',
    type: 'number',
    value: 150,
    comment: '',
  },
  {
    variable: 'usLockPeriod',
    type: 'number',
    value: 31536000,
    comment: 'One Year',
  },
  {
    variable: 'jpInvestorsLimit',
    type: 'number',
    value: 0,
    comment: '',
  },
  {
    variable: 'authorizedSecurities',
    type: 'number',
    value: 0,
    comment: '',
  },
  {
    variable: 'forceFullTransfer',
    type: 'boolean',
    value: 'false',
    comment: '',
  },
  {
    variable: 'worldWideForceFullTransfer',
    type: 'boolean',
    value: 'false',
    comment: '',
  },
  {
    variable: 'forceAccredited',
    type: 'boolean',
    value: 'false',
    comment: '',
  },
  {
    variable: 'forceAccreditedUS',
    type: 'boolean',
    value: 'false',
    comment: '',
  },
  {
    variable: 'disallowBackDating',
    type: 'boolean',
    value: 'false',
    comment: '',
  },
];

export const euCountryCodes = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'GB',
  'IS',
  'LI',
  'NO',
  'CH',
];

export const usCountryCodes = ['US'];

export const forbiddenCountryCodes = ['CN', 'IR', 'SY', 'KP', 'CU', 'UA'];

export const ComplianceType = {
  REGULATED: 'regulated',
  PARTITIONED: 'partitioned',
  WHITELISTED: 'whitelisted',
};
