<template>
  <div class="d-flex flex-column">
    <div
      v-if="this.socketFailure()"
      id="error"
      class="summary"
    >
      <div class="icon">
        <img :src="alertIcon">
      </div>
      <div class="title">
        <span id="title">{{ $t('manageToken.activateToken.deploymentLogs.deploymentFailed') }}</span>
        <span>{{ this.getErrorMessage }}</span>
      </div>
      <button
        class="retry-button"
        @click="this.retryDeployment"
      >
        <img
          class="retry-icon"
          :src="replayIcon"
        >
        {{ $t('manageToken.retry') }}
      </button>
    </div>
    <div
      v-else-if="this.socketSuccess()"
      id="success"
      class="summary"
    >
      <div class="icon">
        <img :src="checkIcon">
      </div>
      <div class="title">
        <span id="title">{{ $t('manageToken.activateToken.deploymentLogs.deploymentSuccessful') }}</span>
        <span>{{ this.getTimeDeployment() }}</span>
      </div>
    </div>
    <div
      v-else-if="this.socketPending()"
      id="pending"
      class="summary"
    >
      <div class="icon">
        <b-spinner
          class="align-middle"
          big
        />
      </div>
      <div id="title">
        <span id="title">{{ $t('manageToken.activateToken.deploymentLogs.deploymentPending') }}</span>
      </div>
    </div>

    <div v-if="this.hasFetchedLogs">
      <div class="header-deployment-logs">
        <span>Deployment Steps</span>
        <collapseAllButton
          :on-click="this.toggleCollapseLogs"
          :show-logs="showingLogs"
        />
      </div>

      <activateStep
        v-for="(step, index) in formatSteps()"
        :key="index"
        :step="step"
        :show-logs="showingLogs"
        :faucet-link="getFaucet()"
        :scanner-link="getScanner()"
      />
    </div>
    <div v-else>
      <span>
        Fetching Logs, please wait...
      </span>
    </div>
    <p style="display:none;">
      {{ isReceivingEvents }}
    </p>
  </div>
</template>


<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
import activateStep from './activate-step.vue';
import collapseAllButton from './collapse-all-button.vue';
import checkIcon from '@/assets/img/check-icon.svg';
import alertIcon from '@/assets/img/alert-icon.svg';
import replayIcon from '@/assets/img/replay.svg';

export default {

  name: 'ActivateSteps',
  components: {
    activateStep,
    collapseAllButton,
  },

  data() {
    return {
      showingLogs: false,
      checkIcon,
      alertIcon,
      replayIcon,
    };
  },

  computed: {
    ...mapState('socketio', ['socketError', 'logMessages', 'isReceivingEvents']),
    ...mapState('configToken', ['deploymentToken']),

    ...mapGetters('configToken', ['tokenDeployed', 'getAvailableNetworks']),
    ...mapGetters('socketio', ['isConnected', 'getErrorMessage', 'isConnecting', 'hasFetchedLogs']),

    getScannerAndFaucet() {
      const availableNetworks = this.getAvailableNetworks;
      let faucetLink = '';
      let scannerLink = '';
      availableNetworks.forEach((network) => {
        if (network.id === this.deploymentToken.provider) {
          faucetLink = network.faucetUrl;
          scannerLink = network.explorerUrl;
        }
      });
      return { faucetLink, scannerLink };
    },
  },

  created() {
    this.getSteps();
  },

  methods: {

    ...mapActions('configToken', ['retryDeploy']),
    ...mapMutations('socketio', ['SOCKET_SUCCESS']),
    ...mapActions('socketio', ['fetchLogs', 'connect', 'disconnect']),

    getSteps() {
      if (!this.hasFetchedLogs && this.deploymentToken.abstractionLayerDeploymentId) {
        this.fetchLogs();
      }
      if (this.deploymentToken.status.toLowerCase() === 'pending' && !this.isConnected && !this.isConnecting) {
        this.connect();
      }
    },
    formatSteps() {
      // We remove the placeholder steps from view to avoid confusion
      const filteredSteps = Object.fromEntries(Object.entries(this.logMessages).filter(v => v[1].status !== 'placeholder')); // filter gives an array key-value

      return Object.entries(filteredSteps).map(([key, value]) => (
        Object.assign({}, { stepNumber: key }, value)
      )).reverse();
    },
    toggleCollapseLogs() {
      this.showingLogs = !this.showingLogs;
    },
    getScanner() {
      const { scannerLink } = this.getScannerAndFaucet;
      return scannerLink || '';
    },
    getFaucet() {
      const { faucetLink } = this.getScannerAndFaucet;
      return faucetLink || '';
    },
    socketSuccess() {
      return this.deploymentToken.status.toLowerCase() === 'success';
    },
    socketPending() {
      return this.deploymentToken.status.toLowerCase() === 'pending' || 'inprogress';
    },
    socketFailure() {
      return this.deploymentToken.status.toLowerCase() === 'failure' || this.socketError;
    },
    getTimeDeployment() {
      const totalSteps = this.logMessages;
      if (Object.keys(totalSteps).length === 0) {
        return '';
      }
      const firstStep = totalSteps['1'];
      const lastStep = totalSteps[`${Object.keys(totalSteps).reduce((a, b) => (Number(a) > Number(b) ? a : b))}`]; // a.k.a get the highest value of the key (the highest step number)
      let delta = (lastStep.finalDateTimeStamp - firstStep.initialDateTimeStamp) / 1000;
      // calculate (and subtract) whole hours
      const hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;

      // calculate (and subtract) whole minutes
      const minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;

      // what's left is seconds
      const seconds = delta % 60;
      return `${this.$t('manageToken.activateToken.deploymentLogs.totalTime')} ${hours}h ${minutes}min ${seconds}seg`;
    },
    retryDeployment() {
      this.retryDeploy(this.deploymentToken.id).then(
        () => {
          const lastStep = this.logMessages[`${Object.keys(this.logMessages).reduce((a, b) => (Number(a) > Number(b) ? a : b))}`]; // a.k.a get the highest value of the key (the highest step number)
          lastStep.status = 'Processing';
          this.$emit('step-submitted', { success: true });
          this.SOCKET_SUCCESS();
          this.connect();
        },
      ).catch(
        () => {
          this.$emit('step-submitted', { success: false });
        },
      );
    },
  },
};
</script>

<style>
.summary {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 16px;
  gap: 12px;
  margin-bottom: 2.6rem;
  /* Inside auto layout */
  align-self: stretch;

}

#error {
  color: #F4374E;

  background: #FEEBED;
  border-radius: 4px;

}

.header-deployment-logs {
  background: #FFFFFF;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
}

#success {
  /* Info/Alert/Background */

  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #15A3CF;
  border-radius: 4px;

  color: #000;
}

#pending {
  background: white;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F5A01D;
  border-radius: 4px;

  color: #000;
}

#title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  letter-spacing: 0.15px;
  color: #000000;
}

.title {
  display: flex;
  flex-direction: column;
  padding: 8px 0px;
  width: 80%;
}

.icon {
  padding: 7px 0px;
  display: flex;
  align-items: flex-start;
}

.retry-button {
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;

  color: white;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.46px;

  background: #F4374E;
  border-radius: 4px;
  border: 0;
}

.retry-icon {
  width: 12px;
  height: 15px;
  margin-right: 0.4rem;
}
</style>
