<template>
  <div class="row header-row">
    <div
      v-for="(tableCol, index) in columns"
      :key="index"
      :class="`col-md-${tableCol.size} form-head-text`"
    >
      <span class="pl-3"> {{ tableCol.title }} </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CpTabularHeaders',
  props: {
    columns: {
      type: Array,
      default: () => [],
      validator: cols => cols.every(col => col.title && col.size),
    },
  },
};
</script>

<style scoped>
.header-row {
  padding-left: 24px;
  padding-right: 24px;
}
.form-head-text {
  font-weight: 500;
}

</style>
