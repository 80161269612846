<template>
  <div class="deployer-wallet-container-lite">
    <div class="deployer-wallet-address-lite">
      <span class="title-column">{{ $t('manageToken.activateToken.deployer.lite.deployerWallet') }}</span>
      <a
        v-if="this.scannerLink"
        :href="this.showLinkToScanner(deployerWallet)"
        target="_blank"
      >{{ deployerWallet }}</a>
      <span
        v-else
      >{{ deployerWallet }}</span>
      <copyToClipboard :link="deployerWallet" />
    </div>
    <div class="token-description-row">
      <span class="title-column">{{ $t('manageToken.activateToken.deployer.lite.available') }}</span>
      <div class="balance-available-lite">
        <span>{{ ` ${ this.availableBalance } ${getProtocolCurrency(this.deploymentToken.provider)}` }}</span>
      </div>
    </div>
    <div class="token-description-row">
      <span class="title-column">{{ $t('manageToken.activateToken.deployer.lite.minimum') }}</span>
      <div class="balance-minimum-lite">
        <span>{{ ` ${ this.minimumBalance } ${getProtocolCurrency(this.deploymentToken.provider)}` }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import copyToClipboard from '@/components/common/copy-to-clipboard';
import warningIcon from '@/assets/img/warning-icon.svg';
import { i18n } from '@/i18n';
import { getDeployerWallet, getDeploymentGasFee, getDeploymentGasPrice } from '~/utilities/external-api/apis/blockchainGwApi';

export default {
    name: 'DeployerWalletLite',
    components: {
        copyToClipboard,
    },

    props: {},

    data() {
        return {
            deployerWallet: 'Loading...',
            warningIcon,
            minimumBalance: 0,
            availableBalance: 0,
        };
    },

    created() {
        this.getNetworkInfo();
        this.getBalances();
    },

    computed: {
        ...mapState('configToken', ['deploymentToken']),

        ...mapGetters('configToken', ['getAvailableNetworks']),

        getScannerAndFaucet() {
            const availableNetworks = this.getAvailableNetworks;
            let faucetLink = '';
            let scannerLink = '';
            availableNetworks.forEach((network) => {
                if (network.id === this.deploymentToken.provider) {
                    faucetLink = network.faucetUrl;
                    scannerLink = network.explorerUrl;
                }
            });
            return { faucetLink, scannerLink };
        },
    },

    methods: {
        ...mapActions('configToken', ['getDeploymentGasCostEstimationInGwei', 'setSubmitButtonDisabled']),
        ...mapMutations('global', ['CALL_ERROR_TOASTER']),

        showLinkToScanner(link) {
            const scannerLink = this.getScanner();
            if (!scannerLink) { return '#'; }
            return `${scannerLink}address/${link}`;
        },
        getScanner() {
            const { scannerLink } = this.getScannerAndFaucet;
            return scannerLink || '';
        },
        getFaucet() {
            const { faucetLink } = this.getScannerAndFaucet;
            return faucetLink || '';
        },
        getProtocolCurrency(provider) {
            const matic = /polygon/;
            const avax = /avalanche/;
            const eth = /ethereum|ganache|goerli|besu|quorum|rinkeby/;
            const algo = /algorand/;

            switch (true) {
                case matic.test(provider):
                    return 'MATIC';
                case avax.test(provider):
                    return 'AVAX';
                case eth.test(provider):
                    return 'ETH';
                case algo.test(provider):
                    return 'ALGO';
                default:
                    return 'ETH';
            }
        },
        isTestnet(provider) {
            const regex = /test|quorum|goerli|kovan|rinkeby|sepolia|mumbai|polygon/; // TODO: Change this regex to the providers used in the current version (dev, RC, sandbox...)
            return regex.test(provider);
        },
        isEthereumPublicNetwork() {
            return this.deploymentToken.provider
                && (this.deploymentToken.provider.includes('ethereum') || this.deploymentToken.provider.includes('polygon'));
        },
        getWalletBalance() {
            getDeployerWallet(this.deploymentToken.provider).then(
                (response) => {
                    this.availableBalance = (response.data.balance / (10 ** 18)).toFixed(3);
                    this.deployerWallet = response.data.address;
                    if (this.errorBalance) {
                        this.setSubmitButtonDisabled(false);
                    } else {
                        this.setSubmitButtonDisabled(Number(this.availableBalance) < Number(this.minimumBalance));
                    }
                },
            )
                .catch(
                    (error) => {
                        this.printError(`${i18n.t('manageToken.activateToken.deployer.walletBalanceError')} ${error}`);
                        this.setSubmitButtonDisabled(false);
                    },
                );
        },
        getBalances() {
            this.setSubmitButtonDisabled(true);
            if (this.networkTxType === 'EIP1559') {
                getDeploymentGasFee(this.deploymentToken.provider).then(
                    (deploymentGas) => {
                        const { maxFeePerGas, maxPriorityFeePerGas } = deploymentGas.data;

                        this.getDeploymentGasCostEstimationInGwei(this.deploymentToken.provider).then(
                            (deploymentGasEstimation) => {
                                const { estimatedDeploymentGas } = deploymentGasEstimation;
                                const gasPriceInWei = parseInt(maxFeePerGas.substring(2), 16) + parseInt(maxPriorityFeePerGas.substring(2), 16);
                                const gasAmount = (gasPriceInWei * estimatedDeploymentGas) / (10 ** 18);
                                this.minimumBalance = gasAmount.toFixed(3);

                                this.getWalletBalance();
                            },
                        ).catch((error) => {
                            this.printError(`${i18n.t('manageToken.activateToken.deployer.minimumGasError')} ${error}`);
                            this.getWalletBalance();
                            this.errorBalance = true;
                        });
                    },
                ).catch((error) => {
                    this.printError(`${i18n.t('manageToken.activateToken.deployer.minimumGasError')} ${error}`);
                    this.getWalletBalance();
                    this.errorBalance = true;
                });
            } else {
                getDeploymentGasPrice(this.deploymentToken.provider).then( // legacy
                    (deploymentGas) => {
                        const { gasPrice } = deploymentGas.data;
                        this.getDeploymentGasCostEstimationInGwei(this.deploymentToken.provider).then(
                            (deploymentGasEstimation) => {
                                const { estimatedDeploymentGas } = deploymentGasEstimation;
                                const gasAmount = (gasPrice * estimatedDeploymentGas) / (10 ** 18);
                                this.minimumBalance = gasAmount.toFixed(3);

                                this.getWalletBalance();
                            },
                        ).catch((error) => {
                            this.printError(`${i18n.t('manageToken.activateToken.deployer.minimumGasError')} ${error}`);
                            this.getWalletBalance();
                            this.errorBalance = true;
                        });
                    },
                )
                    .catch(
                        (error) => {
                            this.printError(`${i18n.t('manageToken.activateToken.deployer.minimumGasError')} ${error}`);
                            this.getWalletBalance();
                            this.errorBalance = true;
                        },
                    );
            }
        },

        printError(err) {
            this.$log.error(err);
            this.CALL_ERROR_TOASTER(err);
        },

        getNetworkInfo() {
            const availableNetworks = this.getAvailableNetworks;

            availableNetworks.forEach((network) => {
                if (network.id === this.deploymentToken.provider) {
                    this.networkTxType = network.networkTxType;
                    this.scannerLink = network.explorerUrl;
                }
            });
        },

    },
};
</script>
<style scoped>
.deployer-wallet-container-lite {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border-top: 1px solid #0000001F;
    padding-top: 1.25rem;
}

.deployer-wallet-container-lite h2 {
    font-size: 18px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0.0015em;
    text-align: left;

    color: #0A1828;

}

.warning-icon {
    width: 36.67px;
    height: 31.67px;

    filter: brightness(0) saturate(100%);
    filter: invert(44%) sepia(92%) saturate(5736%) hue-rotate(336deg) brightness(94%) contrast(110%);
}

.deployer-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;
    color: #0283A8;

}

.deployer-warning {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 12px;
    gap: 12px;

    text-align: center;

    background: #FFFFFF;
    border: 1px solid #FC233E;

    color: #000000;
}

.deployer-balance {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 12px 24px;
    gap: 30px;
    background: #F7F7F7;
    border-radius: 12px;
    width: 100%;
}

.balance-summary {
    display: flex;
    flex-direction: column;
    padding: 8px;

    background: #FFFFFF;
    border-radius: 8px;
    width: 40%;
    color: #000000;
}

.balance-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height, or 150% */

    color: #000000;
}

.balance-available-lite,
.balance-minimum-lite {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.testnet-alert {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    gap: 2px;

    background: #FFE9B1;
    border-radius: 5px;

    color: #000000;
}

.deployer-wallet-alert {
    display: inline-block;
    text-overflow: ellipsis;
    width: 8ch;
    overflow-x: hidden;
    vertical-align: bottom;
}

.balance-value {
    font-weight: 700;
}

.deployer-wallet-address-lite a {
    color: #0A1828;
    display: inline-block;
    text-overflow: ellipsis;
    width: 8ch;
    overflow-x: hidden;
    vertical-align: bottom;
}
</style>
