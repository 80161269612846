<template>
  <a @click="copyLinkToClipboard()">
    <img
      :src="iconUrl"
      class="clipboard"
    >
  </a>
</template>

<script>
import { mapMutations } from 'vuex';
import CopyToClipboardIcon from '~/assets/img/copy-to-clipboard.svg';
import { i18n } from '@/i18n';


export default {
    name: 'CopyToClipboardIcon',
    props: {
        link: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            iconUrl: CopyToClipboardIcon,
        };
    },
    methods: {
        ...mapMutations('global', ['CALL_TOASTER']),
        copyLinkToClipboard() {
            navigator.clipboard.writeText(this.link);
            this.CALL_TOASTER(i18n.t('store.toaster.common.copyToClipboard'));
        },
    },
};
</script>
<style>
.clipboard:hover,
.clipboard:active {
    filter: brightness(0) saturate(100%);
    filter: invert(51%) sepia(88%) saturate(2682%) hue-rotate(161deg) brightness(98%) contrast(84%);
    /* These two filter attributes transform the entire SVG into white. See this: https://codepen.io/sosuke/pen/Pjoqqp */
}
</style>
