<template>
  <ul class="nav nav-tabs tabs-alt nav-responsive-xl">
    <li
      v-for="tab in tabs"
      :key="tab.value"
      class="nav-item"
      @click="handleTabSelect(tab)"
    >
      <span
        :class="getNavLinkClass(tab)"
      >
        {{ tab.text }}
      </span>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    tabs: {
      default: () => [{ text: 'tab', value: 'tab' }],
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  mounted() {
    if (!this.value) this.handleTabSelect(this.tabs[0]);
  },
  methods: {
    getNavLinkClass({ value }) {
      if (this.value === value) {
        return 'nav-link active';
      }
      return 'nav-link';
    },
    handleTabSelect({ value }) {
      this.$emit('input', value);
    },
  },
};
</script>

<style scoped>
  .nav-link{
    cursor: pointer;
  }

</style>
