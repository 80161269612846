import ComplianceStrategy
  from './compliance-strategy';
import {
  euCountryCodes,
  forbiddenCountryCodes,
  usCountryCodes,
} from './compliance-rules';

export default class StandardComplianceStrategy extends ComplianceStrategy {
  getDefaultCountriesCompliance = () => {
    const countryRowFields = [];
    euCountryCodes
      .forEach(country => countryRowFields.push({ countryName: country, complianceStatus: 'eu', comment: '' }));
    usCountryCodes
      .forEach(country => countryRowFields.push({ countryName: country, complianceStatus: 'us', comment: '' }));
    forbiddenCountryCodes
      .forEach(country => countryRowFields.push({ countryName: country, complianceStatus: 'forbidden', comment: '' }));

    return countryRowFields;
  }
}
