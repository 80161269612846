var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cp-general-modal',{ref:"viewHistoryModal",attrs:{"size":"lg","hide-footer":true}},[_c('cp-table',{staticClass:"hide-hr transactionTable",attrs:{"get-data-action":"configToken/getRulesSnapshot","fields":_vm.complianceRulesFields,"url-params":_vm.complianceRules,"without-search":"","without-pagination":""},scopedSlots:_vm._u([{key:"variable",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',{staticClass:"var-name"},[_vm._v(_vm._s(_vm.$t(("manageToken.complianceRules.variables." + (rowData.item.variable)))))])]}}])}),_c('cp-table',{staticClass:"hide-hr country-compliance-table",attrs:{"get-data-action":"configToken/getCountriesSnapshot","fields":_vm.countryComplianceFields,"url-params":_vm.countriesCompliance,"without-pagination":"","without-search":""},scopedSlots:_vm._u([{key:"variable",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',{staticClass:"countries-compliance var-name"},[_vm._v(_vm._s(_vm.$t(("manageToken.complianceRules.variables." + (rowData.item.variable)))))])]}}])})],1),_c('cp-table',{ref:"complianceHistory",staticClass:"hide-hr",attrs:{"get-data-action":"configToken/getComplianceHistory","fields":_vm.historyTableFields,"url-params":_vm.urlParams,"without-search":"","without-pagination":""},scopedSlots:_vm._u([{key:"createdAt",fn:function(ref){
var item = ref.rowData.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("unixDateFilter")(item.createdAt))+" ")])]}},{key:"view",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],staticClass:"mr-3",attrs:{"variant":"default view-operator-btn btn-xs md-btn-flat","title":_vm.$t('common.button.view')},on:{"click":function($event){return _vm.toViewModal(rowData.item)}}},[_vm._v(" "+_vm._s(_vm.$t('common.button.view'))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }