<template>
  <b-alert
    show
    dismissible
    variant="warning"
  >
    <h4 class="alert-heading">
      Warning!
    </h4>
    <p>
      {{ $t('manageToken.complianceRules.warning') }}
    </p>
  </b-alert>
</template>
<script>
export default {
  name: 'Warning',
};
</script>

<style scoped>
.alert-warning {
  margin: 0 3rem 3rem;
}
</style>
