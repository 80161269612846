<template>
  <div>
    <div class="card mt-3 mb-4">
      <div class="card-header mb-3">
        <div class="row align-items-left">
          <div class="col-md-6">
            <h4 class="mb-0 text-primary">
              {{ $t('manageToken.setTokenContract.setTokenContract') }}
            </h4>
          </div>
        </div>
      </div>
      <cp-input-container
        ref="cpInputContainer"
        v-model="tokenModel"
      >
        <div class="card-body">
          <div class="row align-items-left">
            <div class="col-md-5">
              <cp-input
                v-model="tokenModel.address"
                :label="$t('manageToken.setTokenContract.tokenAddress')"
                :name="$t('manageToken.setTokenContract.tokenAddress')"
                :readonly-placeholder="$t('manageToken.setTokenContract.pendingTokenDeployment')"
                is-readonly
              />
              <cp-input
                v-model="tokenModel.name"
                :placeholder="$t('manageToken.setTokenContract.appleExample')"
                :label="$t('manageToken.setTokenContract.tokenName')"
                :name="$t('manageToken.setTokenContract.tokenName')"
                validate="required"
                :is-readonly="!editMode"
              />
            </div>
            <div class="col-md-5">
              <cp-input
                v-model="tokenModel.symbol"
                :label="$t('manageToken.setTokenContract.tokenTicker')"
                :name="$t('manageToken.setTokenContract.tokenTicker')"
                :placeholder="$t('manageToken.setTokenContract.appleTickerExample')"
                :validate="validateTickerInput"
                :is-readonly="!editMode"
              />
              <div class="row">
                <div class="col-md-6">
                  <cp-input
                    v-model="tokenModel.decimals"
                    :label="$t('manageToken.setTokenContract.tokenDecimals')"
                    :name="$t('manageToken.setTokenContract.tokenDecimals')"
                    :placeholder="$t('manageToken.setTokenContract.zero')"
                    class="decimals"
                    validate="required|between:0,16"
                    :is-readonly="!editMode"
                  />
                </div>
                <div class="provider col-md-6">
                  <cp-select
                    v-model="tokenModel.provider"
                    :name="$t('manageToken.setTokenContract.tokenBlockchain')"
                    :label="$t('manageToken.setTokenContract.tokenBlockchain')"
                    :options="providerOptions"
                    input-class="cp-input-override"
                    :disabled="!editMode"
                    validate="required"
                    @input="onProviderChange"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="displayAlgorandInput"
              class="col-md-5"
            >
              <cp-input
                v-model="tokenModel.metadataHash"
                :label="$t('manageToken.setTokenContract.tokenMetadataHash')"
                :name="$t('manageToken.setTokenContract.tokenMetadataHash')"
                :is-readonly="!editMode"
              />
            </div>
            <div
              v-if="displayAlgorandInput"
              class="col-md-5"
            >
              <cp-input
                v-model="tokenModel.url"
                :label="$t('manageToken.setTokenContract.tokenUrl')"
                :name="$t('manageToken.setTokenContract.tokenUrl')"
                :placeholder="$t('manageToken.setTokenContract.tokenUrlExample')"
                :is-readonly="!editMode"
              />
            </div>
            <div
              v-if="displayAlgorandInput"
              class="col-md-5"
            >
              <cp-input
                v-model="tokenModel.totalSupply"
                :label="$t('manageToken.setTokenContract.tokenTotalSupply')"
                :name="$t('manageToken.setTokenContract.tokenTotalSupply')"
                :placeholder="$t('manageToken.setTokenContract.totalSupplyExample')"
                class="number"
                validate="required|between:1,100000000000"
                :is-readonly="!editMode"
              />
            </div>
          </div>
        </div>
      </cp-input-container>
    </div>
    <div
      v-if="shouldSeeWeb3FlowConfig"
      class="card mt-3 mb-4"
    >
      <div class="card-header mb-3">
        <div class="row align-items-left">
          <div class="col-md-6">
            <h4 class="mb-0 text-primary">
              {{ $t('manageToken.swapContract.web3FlowConfig') }}
            </h4>
          </div>
        </div>
      </div>
      <cp-input-container
        ref="cpSwapContainer"
      >
        <div class="card-body">
          <div class="row align-items left">
            <div class="col-md-6">
              <cp-input
                v-model="swapModel.custodianAddress"
                :label="$t('manageToken.swapContract.custodianAddress')"
                name="custodianAddress"
                :is-readonly="(!editMode || !swapModelEnabled)"
                validate="wallet_address:ETH"
              />
            </div>
            <div class="col-md-6">
              <cp-select
                v-model="swapModel.selectedStableCoin"
                :label="$t('manageToken.swapContract.stableCoin')"
                :options="stableCoinOptions"
                input-class="cp-input-override"
                :disabled="(!editMode || !swapModelEnabled)"
              />
            </div>
          </div>
          <div class="row align-items left mt-3">
            <div class="col-md-6">
              <cp-input
                v-model="swapModel.swapContractAddress"
                :label="$t('manageToken.swapContract.swapContractAddress')"
                name="swapContractAddress"
                :readonly-placeholder="$t('manageToken.swapContract.swapContractPending')"
                is-readonly
              />
            </div>
          </div>
        </div>
      </cp-input-container>
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash';
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import BigNumber from 'bignumber.js';
import CpEditableMixin from '~/mixins/editable-component';
import CpInputContainer from '~/components/common/cpInputContainer';
import { CpInput, CpSelect } from '~/components/common/standalone-components/inputs';
import { i18n } from '@/i18n';

export default {
  name: 'CpTokenContract',
  components: {
    CpInput,
    CpInputContainer,
    CpSelect,
  },

  mixins: [CpEditableMixin],

  props: {
    submitClicked: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      errors: [],
      editMode: false,
      swapModelEnabled: false,
      providerOptions: [],
      swapModel: {
        selectedStableCoin: '',
        custodianAddress: '',
        swapContractAddress: '',
      },
      tokenModel: {
        name: '',
        address: '',
        symbol: '',
        decimals: '',
        provider: '',
        url: '',
        metadataHash: '',
        totalSupply: '',
      },
    };
  },
  computed: {
    ...mapState('configToken', ['deploymentToken', 'deploymentExists']),
    ...mapGetters('stableCoins', ['stableCoins', 'providerId']),
    ...mapGetters('configToken', ['getTokenDescription', 'getAvailableNetworks', 'getSwapContractModel', 'getOwners']),
    ...mapGetters('issuersInfo', ['issuerInfo', 'issuerTokens']),
    ...mapGetters({
      tokenConfiguration: 'configuration/getTokenConfiguration',
    }),
    hasSwapContractModel() {
      return !_.isEmpty(this.swapModel.custodianAddress);
    },
    hasProviderChanged() {
      return this.providerId !== this.tokenModel.provider;
    },
    displayAlgorandInput() {
      return this.tokenModel.provider && this.tokenModel.provider.includes('algorand');
    },
    validateTickerInput() {
      if (this.tokenModel.provider && this.tokenModel.provider.includes('algorand')) {
        return 'required|max:8';
      }
      return 'required';
    },
    shouldSeeWeb3FlowConfig() {
      return this.tokenModel.status === 'initial'
        || (this.tokenModel.status !== 'initial' && this.hasSwapContractModel);
    },
    stableCoinOptions() {
      if (this.stableCoins.length > 0) {
        return this.stableCoins.map(coin => ({
            value: coin.name,
            text: coin.name,
          }));
      }

      return [];
    },
  },
  watch: {
    submitClicked(value) {
      if (value) {
        this.submitInfo();
      }
    },
    stableCoinOptions(value) {
      if (value.length > 0) {
        this.swapModelEnabled = true;
        this.swapModel.selectedStableCoin = this.swapModel.selectedStableCoin ? this.swapModel.selectedStableCoin : this.stableCoinOptions[0].value;
      } else {
        this.swapModelEnabled = false;
        this.clearSwapModel();
      }
    },
  },
  async created() {
    this.tokenModel = { ...this.getTokenDescription };

    if (this.tokenModel.provider) {
      await this.setStableCoinsByProvider({ providerId: this.tokenModel.provider });
    }

    this.tokenModel.totalSupply = this.geTotalSupplySanitizedValue(this.tokenModel.totalSupply, this.tokenModel.decimals);
    const { status } = this.getTokenDescription;
    const availableNetworks = this.getAvailableNetworks;
    if (availableNetworks && availableNetworks.length > 0) {
      this.providerOptions = _(availableNetworks)
        .map(network => ({
          value: network.id,
          text: network.name,
        }))
        .orderBy('sort_order', 'asc')
        .value();
    }

    const { stableCoin, custodianAddress } = this.getSwapContractModel;

    this.swapModel = {
      selectedStableCoin: stableCoin,
      custodianAddress,
      swapContractAddress: this.deploymentToken.swapContractAddress || '',
    };

    if (this.issuerInfo.blockchain === 'algorand') {
      this.providerOptions = this.providerOptions.filter(provider => provider.value.includes('algorand'));
    } else {
      this.providerOptions = this.providerOptions.filter(provider => !provider.value.includes('algorand'));
    }

    this.tokenModel.name = this.tokenModel.name || this.tokenConfiguration.tokenName;
    this.editMode = !status || status === 'initial';
  },

  methods: {
    ...mapActions(
      'configToken', ['addTokenDeployment', 'updateTokenDeployment'],
    ),
    ...mapActions(
      'stableCoins', ['setStableCoinsByProvider'],
    ),
    ...mapState('configToken', ['deploymentToken']),
    ...mapMutations('global', ['CALL_ERROR_TOASTER']),
    ...mapMutations('configToken', ['SET_SWAP_CONTRACT']),
    clearSwapModel() {
      this.swapModel = {
        selectedStableCoin: '',
        custodianAddress: '',
        swapContractAddress: '',
      };
    },
    async onProviderChange() {
      if (!this.providerId || this.hasProviderChanged) {
        await this.setStableCoinsByProvider({ providerId: this.tokenModel.provider });
      }
    },
    getTotalSupplyValue(value, decimals) {
      if (value) {
        return (new BigNumber(value).multipliedBy(new BigNumber(10).pow(decimals))).toString(10);
      }
      return value;
    },
    geTotalSupplySanitizedValue(value, decimals) {
      if (value) {
        return (new BigNumber(value).div((new BigNumber(10).pow(decimals)))).toString(10);
      }
      return value;
    },
    validateCustodianAddress() {
      const persistentSwapContract = this.getSwapContractModel;
      if (persistentSwapContract) {
        this.swapContractModel = { ...persistentSwapContract };
      }
      const persistentOwners = this.getOwners;
      const { roles } = this.deploymentToken;
      const roleAddresses = roles
          ? roles.map(roleObject => (
              {
                address: roleObject.walletAddress || roleObject.address,
                role: roleObject.role,
                type: roleObject.ownership,
              }))
          : [];

      if (roleAddresses.find(role => this.checkCustodianAddressEqualsTo(role.address))) {
        this.printError(i18n.t('store.toaster.configToken.manageToken.custodianAddressMustNotBeIssuerOrExchangeAddress'));
        return false;
      }
      if (this.checkCustodianAddressEqualsTo(this.deploymentToken.owners.tokenOwner)) {
        this.printError(i18n.t('store.toaster.configToken.manageToken.custodianWalletAddressEqualsToMaster'));
        return false;
      }
      if (this.checkCustodianAddressEqualsTo(this.deploymentToken.owners.walletRegistrarOwner)) {
        this.printError(i18n.t('store.toaster.configToken.manageToken.custodianWalletAddressEqualsToWalletRegistrar'));
        return false;
      }
      if (persistentOwners && this.checkCustodianAddressEqualsTo(persistentOwners.registrarOwnerAddress)) {
        this.printError(i18n.t('store.toaster.configToken.manageToken.custodianWalletAddressEqualsToWalletRegistrar'));
        return false;
      }

      if (this.checkCustodianAddressEqualsTo(this.deploymentToken.owners.redemptionAddress)) {
        this.printError(i18n.t('store.toaster.configToken.manageToken.custodianWalletAddressEqualsToRedemption'));
        return false;
      }

      if (persistentOwners && this.checkCustodianAddressEqualsTo(persistentOwners.redemptionAddress)) {
        this.printError(i18n.t('store.toaster.configToken.manageToken.custodianWalletAddressEqualsToRedemption'));
        return false;
      }

      if (this.checkCustodianAddressEqualsTo(this.deploymentToken.owners.omnibusTBEAddress)) {
        this.printError(i18n.t('store.toaster.configToken.manageToken.custodianWalletAddressEqualsToOmnibusTbe'));
        return false;
      }

      if (persistentOwners && this.checkCustodianAddressEqualsTo(persistentOwners.omnibusTBEAddress)) {
        this.printError(i18n.t('store.toaster.configToken.manageToken.custodianWalletAddressEqualsToOmnibusTbe'));
        return false;
      }

      return true;
    },
    checkCustodianAddressEqualsTo(addressToCompare) {
      if (this.swapModel.custodianAddress && addressToCompare) {
        return this.swapModel.custodianAddress.toLowerCase() === addressToCompare.toLowerCase();
      }
      return false;
    },
    printError(err) {
      this.$log.error('Token description error:', err);
      this.CALL_ERROR_TOASTER(err);
    },
    submitInfo() {
      const issuerId = this.$route.params.idIssuer;
      const { tokenId } = this.$route.params;
      this.$refs.cpInputContainer.validateForm().then((isValid) => {
        if (!isValid) {
          this.$emit('step-submitted', { success: false });
          return;
        }
        this.editMode = false;
        const {
          name, symbol, decimals, provider, url, metadataHash,
        } = this.tokenModel;

        const params = {
          name,
          symbol,
          decimals,
          provider,
          deploymentId: tokenId,
          url,
          metadataHash,
          totalSupply: this.getTotalSupplyValue(this.tokenModel.totalSupply, this.tokenModel.decimals),
        };

        /**
         * Adds the swap contract model if the swap contract is enabled
         */
        if (this.hasSwapContractModel) {
          params.swapContract = {
            stableCoin: this.swapModel.selectedStableCoin,
            custodianAddress: this.swapModel.custodianAddress,
          };
          if (!this.validateCustodianAddress()) {
            this.editMode = true;
            this.$emit('step-submitted', { success: false });
            return;
          }
          this.SET_SWAP_CONTRACT(params.swapContract);
        }

        const addOrUpdateTokenDescription = this.deploymentExists ? 'updateTokenDeployment' : 'addTokenDeployment';
        this[addOrUpdateTokenDescription]({
          issuerId,
          tokenId,
          params,
        }).then(
          () => {
            this.$emit('step-submitted', { success: true, provider: this.tokenModel.provider });
          },
        ).catch(
          () => {
            this.$emit('step-submitted', { success: false });
          },
        ).finally(
          () => {
            this.editMode = true;
          },
        );
      });
    },
  },
};
</script>

<style scoped>
.decimals {
  width: 100px;
}
</style>
