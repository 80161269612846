<template>
  <button
    class="collapse-button"
    @click="this.onClick"
  >
    <img :src="this.showButtonIcon">
    <span class="collapse-text">
      {{ this.showButtonText }}
    </span>
  </button>
</template>

<script>
import openIcon from '~/assets/img/open-vector.svg';
import closeIcon from '~/assets/img/close-vector.svg';

export default {

    name: 'CollapseAllButton',

    props: {
        onClick: {
            type: Function,
        },
        showLogs: {
            type: Boolean,
        },
    },

    computed: {
        showButtonText() {
            return this.showLogs ? this.$t('manageToken.activateToken.deploymentLogs.collapseLogs') : this.$t('manageToken.activateToken.deploymentLogs.displayLogs');
        },
        showButtonIcon() {
            return this.showLogs ? closeIcon : openIcon;
        },
    },
};
</script>

<style>
.collapse-button {
    border: 1px solid #0283A8;
    color: #0283A8;
    border-radius: 4px;
    background-color: transparent;
    width: 103px;
    display: flex;
    justify-content: space-around;
}

.collapse-button:hover {
    background-color: #0283A8;
    color: white;
}

.collapse-button:active {
    background-color: #0283A8;
    color: white;
}

.collapse-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
}

img {
    width: 18px;
    height: 18px;
}

.collapse-button:hover img {
    filter: brightness(0) saturate(100%);
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(42deg) brightness(107%) contrast(101%);
    /* This two filter attributes transform the entire SVG into white. See this: https://codepen.io/sosuke/pen/Pjoqqp */
}

button:focus {
    outline: none !important;
}
</style>
