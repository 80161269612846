import {
  StandardComplianceStrategy,
  WhitelistedComplianceStrategy,
  ComplianceType,
} from './compliance';


export default class ComplianceManager {
  static getComplianceStrategy(
    compliance,
  ) {
    let strategy;
    switch (compliance) {
      case ComplianceType.REGULATED:
        strategy = new StandardComplianceStrategy();
        strategy.complianceType = ComplianceType.REGULATED;
        break;

      case ComplianceType.PARTITIONED:
        strategy = new StandardComplianceStrategy();
        strategy.complianceType = ComplianceType.PARTITIONED;
        break;

      case ComplianceType.WHITELISTED:
        strategy = new WhitelistedComplianceStrategy();
        strategy.complianceType = ComplianceType.WHITELISTED;
        break;

      default:
        strategy = new StandardComplianceStrategy();
        strategy.complianceType = ComplianceType.REGULATED;
        break;
    }

    return strategy;
  }
}
