import {
  ComplianceRulesStandard,
  ComplianceType,
} from '@/pages/_idIssuer/configuration/fundraise-token/manage-token/components/common/compliance/compliance-rules';

export default class ComplianceStrategy {
  #complianceType;

  get complianceType() {
    return this.#complianceType;
  }

  set complianceType(complianceType) {
    this.#complianceType = complianceType;
  }

  getDefaultCompliance = () => {
    let defaultCompliance = [];

    switch (this.#complianceType) {
      case ComplianceType.REGULATED:
      case ComplianceType.PARTITIONED:
        defaultCompliance = ComplianceRulesStandard;
        break;
      case ComplianceType.WHITELISTED:
        defaultCompliance = [
          ComplianceRulesStandard.find(
            value => value.variable === 'authorizedSecurities',
          ),
        ];
        break;
      default:
        defaultCompliance = ComplianceRulesStandard;
    }

    return this.toTableData(defaultCompliance);
  };

  getRowFields = data => Object.keys(data).map(v => ({ variable: v, ...data[v] }));

  toTableData = data => Object.assign({}, this.getRowFields(data), {});
}
