<template>
  <div>
    <div
      v-if="ready"
      class="container-fluid flex-grow-1 container-p-y"
    >
      <b-button
        size="sm"
        variant="margin-reset btn btn-outline-primary btn-sm mb-2"
        @click="backToToken"
      >
        <i class="ion-ios-arrow-back" />
        {{ $t('manageToken.backToToken') }}
      </b-button>
      <cp-configuration-head page-name="Token" />
      <cp-token-deployment-steps
        :selected-step="currentStep"
        @step-changed="handleStepChanged($event)"
      />
      <keep-alive exclude="cp-activate-token">
        <component
          :is="stepComponents[currentStep]"
          :submit-clicked="submitClicked"
          @step-submitted="handleSubmit($event)"
        />
      </keep-alive>
      <div :class="'d-flex flex-row ' + submitButtonClass">
        <cp-button
          v-if="!hideSubmit"
          custom-class="submit-button"
          :disabled="isDeployButtonDisabled"
          size="md"
          @click="submitClickHandler"
        >
          {{ submitLabel }}
        </cp-button>
      </div>
    </div>
    <div
      v-else
      class="row align-items-center mb-2 spinner"
    >
      <div class="col-md-12 text-center">
        <b-spinner class="align-middle" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import CpConfigurationHead from '../../configuration-head';
import CpTokenDeploymentSteps from './components/token-deployment-steps';
import CpTokenContract from './components/token-contract';
import CpComplianceRules from './components/compliance-rules';
import CpManageRoles from './components/manage-roles';
import CpActivateToken from './components/activate-token';
import CpButton from '~/components/common/standalone-components/cp-button';
import routeNames from '~/utilities/routes';

export default {
  name: 'ManageToken',

  metaInfo: {
    title: 'Token',
  },

  components: {
    CpButton,
    CpTokenDeploymentSteps,
    CpConfigurationHead,
    CpTokenContract,
    CpComplianceRules,
    CpManageRoles,
    CpActivateToken,
  },

  data() {
    return {
      ready: false,
      currentStep: 0,
      submitClicked: false,
      stepComplete: false,
      stepComponents: ['cp-token-contract', 'cp-compliance-rules', 'cp-manage-roles', 'cp-activate-token'],
    };
  },

  computed: {
    ...mapState('configToken', ['deploymentToken', 'submitButtonDisabled']),
    ...mapGetters('configToken', [
      'hasCompliance',
      'hasOwners',
      'hasRoles',
      'tokenDeployed']),
    isAlgorandNetwork() {
      return !!(this.deploymentToken && this.deploymentToken.provider && this.deploymentToken.provider.includes('algorand'));
    },
    hideSubmit() {
      return ((this.deploymentToken.status !== 'initial' && this.deploymentToken.status !== 'failure')
        && (this.currentStep === 0 || this.currentStep === 3))
          || ((this.deploymentToken.status !== 'initial' && this.deploymentToken.status !== 'failure') && this.isAlgorandNetwork);
    },
    isDeployButtonDisabled() {
      return this.submitButtonDisabled;
    },
    submitLabel() {
      if (this.deploymentToken.status !== 'initial' && this.deploymentToken.status !== 'failure') {
        return this.$t('manageToken.deploy');
      }
      if (this.stepComponents[this.currentStep] === 'cp-activate-token') {
        if (this.deploymentToken.status === 'failure') {
          return this.$t('manageToken.retry');
        }
        return this.$t('manageToken.activate');
      }
      return this.$t('manageToken.continue');
    },
    submitButtonClass() {
      return this.stepComponents[this.currentStep] === 'cp-activate-token'
        ? 'justify-content-center' : 'justify-content-end';
    },
  },
  watch: {
    $route({ params: { tokenId: newToken, idIssuer } }, { params: { tokenId: oldToken } }) {
      if (oldToken !== newToken) this.$router.push(routeNames.tokenConfiguration(idIssuer, newToken));
    },
  },
  created() {
    const { tokenId } = this.$route.params;
    const issuerId = this.$route.params.idIssuer;
    this.getTokenDeployment({ issuerId, tokenId }).then((tokenDeployment) => {
      Promise.all([
        this.setInitialStep(tokenDeployment),
        this.getTokenConfiguration({ issuerId, tokenId }),
        this.getAvailableNetworks(),
      ]).finally(() => {
        this.ready = true;
      });
    }).catch((err) => {
      if (err.response && err.response.status === 404) {
        Promise.all([
          this.setInitialStep({ status: 'initial' }),
          this.getTokenConfiguration({ issuerId, tokenId }),
          this.getAvailableNetworks(),
        ]).finally(() => {
          this.ready = true;
        });
      }
      this.$log.error('Get Deployments error:', err);
      return err;
    });
  },

  methods: {
    ...mapActions('configToken', ['getTokenDeployment', 'selectStep', 'getAvailableNetworks', 'setSubmitButtonDisabled']),
    ...mapActions('configuration', ['getTokenConfiguration']),
    setInitialStep(tokenDeployment) {
      if (this.tokenDeployed) {
        if (!this.isAlgorandNetwork) {
          this.currentStep = 1; // Go to "Compliance Rules" as "most used after deployed"
          return;
        }
      } else if ((this.hasCompliance() || this.isAlgorandNetwork) && (!this.hasRoles || !this.hasOwners)) {
        this.currentStep = 2; // Go to "Manage Roles" step
        return;
      } else if ((this.hasCompliance() || this.isAlgorandNetwork) && this.hasRoles && this.hasOwners) {
        this.currentStep = 3; // Go to "Activate Token" step if not deployed yet
        return;
      } else if (tokenDeployment.provider && !this.isAlgorandNetwork) {
        this.currentStep = 1;
        return;
      }

      this.currentStep = 0;
    },
    handleStepChanged(index) {
      this.currentStep = index;
      if (index !== 4) {
        this.setSubmitButtonDisabled(false);
      }
    },
    shouldChangeStepOnSubmit() {
      return this.deploymentToken.status === 'initial' && this.currentStep < 3;
    },
    shouldCreateTransactionSignature() {
      return this.deploymentToken.status !== 'initial' && this.deploymentToken.status !== 'failure';
    },
    handleSubmit(response) {
      if (response.success && this.shouldChangeStepOnSubmit()) {
        const newTokenContractProviderIsAlgorand = response.provider ? response.provider.includes('algorand') : false;
        if (
          (this.isAlgorandNetwork || newTokenContractProviderIsAlgorand)
          && this.currentStep === 0
        ) {
          this.currentStep += 2;
        } else {
          this.currentStep += 1;
        }
      }
      if (this.shouldCreateTransactionSignature() && response.success && response.operation === 'set-roles') {
        this.setSubmitButtonDisabled(true);
      }
      this.submitClicked = false;
    },
    submitClickHandler() {
      this.submitClicked = true;
    },
    backToToken() {
      const { idIssuer, tokenId } = this.$route.params;
      this.$router.push(routeNames.tokenConfiguration(idIssuer, tokenId));
    },
    resetActivate() {
      this.setSubmitButtonDisabled(false);
    },
  },
};
</script>

<style scoped>
.submit-button {
  padding: 14px 85px;
  font-size: 16px;
  font-weight: 500;
}
.spinner {
  min-height: 60vh;
}
</style>
