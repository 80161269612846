<template>
  <div>
    <cp-general-modal
      ref="viewHistoryModal"
      size="lg"
      :hide-footer="true"
    >
      <cp-table
        get-data-action="configToken/getRulesSnapshot"
        :fields="complianceRulesFields"
        :url-params="complianceRules"
        without-search
        without-pagination
        class="hide-hr transactionTable"
      >
        <template
          slot="variable"
          slot-scope="{ rowData }"
        >
          <span class="var-name">{{ $t(`manageToken.complianceRules.variables.${rowData.item.variable}`) }}</span>
        </template>
      </cp-table>
      <cp-table
        get-data-action="configToken/getCountriesSnapshot"
        :fields="countryComplianceFields"
        :url-params="countriesCompliance"
        without-pagination
        without-search
        class="hide-hr country-compliance-table"
      >
        <template
          slot="variable"
          slot-scope="{ rowData }"
        >
          <span class="countries-compliance var-name">{{ $t(`manageToken.complianceRules.variables.${rowData.item.variable}`) }}</span>
        </template>
      </cp-table>
    </cp-general-modal>
    <cp-table
      ref="complianceHistory"
      get-data-action="configToken/getComplianceHistory"
      :fields="historyTableFields"
      :url-params="urlParams"
      without-search
      without-pagination
      class="hide-hr"
    >
      <template
        slot="createdAt"
        slot-scope="{rowData: {item}}"
      >
        <span> {{ item.createdAt | unixDateFilter }} </span>
      </template>
      <template
        slot="view"
        slot-scope="{ rowData }"
      >
        <b-btn
          v-b-tooltip.hover="true"
          class="mr-3"
          variant="default view-operator-btn btn-xs md-btn-flat"
          :title="$t('common.button.view')"
          @click="toViewModal(rowData.item)"
        >
          {{ $t('common.button.view') }}
        </b-btn>
      </template>
    </cp-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CpTable from '~/components/shared/cp-table';
import CpGeneralModal from '~/components/common/modals-components/general-modal';

export default {
  name: 'CpComplianceHistory',
  components: {
    CpTable,
    CpGeneralModal,
  },
  data() {
    return {
      complianceRules: {},
      countriesCompliance: {},
      historyTableFields: [
        { key: 'createdAt', label: this.$t('manageToken.complianceHistory.historyTableFields.date') },
        { key: 'blockNumber', label: this.$t('manageToken.complianceHistory.historyTableFields.blockNumber') },
        { key: 'status', label: this.$t('manageToken.complianceHistory.historyTableFields.status') },
        { key: 'view', cta: { view: 'View' } },
      ],
      complianceRulesFields: [
        { key: 'variable', label: this.$t('manageToken.complianceHistory.complianceRulesHistoryFields.variable') },
        { key: 'value', label: this.$t('manageToken.complianceHistory.complianceRulesHistoryFields.value') },
        { key: 'comment', label: this.$t('manageToken.complianceHistory.complianceRulesHistoryFields.comment') },
      ],
      countryComplianceFields: [
        { key: 'variable', label: this.$t('manageToken.complianceHistory.complianceRulesHistoryFields.variable') },
        { key: 'value', label: this.$t('manageToken.complianceHistory.complianceRulesHistoryFields.value') },
      ],
      urlParams: { deploymentId: this.$route.params.tokenId },
    };
  },
  computed: {
    ...mapState('configToken', ['deploymentToken']),
  },
  watch: {
    deploymentToken(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$refs.complianceHistory.updateTableData();
      }
    },
  },
  methods: {
    toViewModal(rowData) {
      this.complianceRules = { createdAt: rowData.createdAt };
      this.countriesCompliance = { createdAt: rowData.createdAt };
      this.$refs.viewHistoryModal.show();
    },
  },
};
</script>

<style>
.hide-hr hr {
  display:none;
}
.country-compliance-table {
  height: 300px;
  overflow-y: auto;
}

.countries-compliance {
  position: fixed;
}
.country-compliance-table thead {
  display: none;
}
</style>
