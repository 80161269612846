<template>
  <div class="deployer-wallet-container">
    <div class="deployer-title">
      <h2>{{ $t('manageToken.activateToken.deployer.deployerWallet') }}</h2>
      <div>
        <copyToClipboard :link="deployerWallet" />
        <a
          v-if="this.scannerLink"
          :href="this.showLinkToScanner(deployerWallet)"
          target="_blank"
        >{{ deployerWallet }}</a>
        <span
          v-else
        >{{ deployerWallet }}</span>
      </div>
    </div>
    <div class="deployer-warning">
      <img
        :src="this.warningIcon"
        class="warning-icon"
      >
      <span>{{ $t('manageToken.activateToken.deployer.deployerWalletWarning') }}</span>
      <img
        :src="this.warningIcon"
        class="warning-icon"
      >
    </div>
    <div class="deployer-balance">
      <div class="balance-summary">
        <span class="balance-title">{{ $t('manageToken.activateToken.deployer.balance') }}</span>
        <div class="balance-available">
          <span>{{ $t('manageToken.activateToken.deployer.available') }}</span>
          <span><span class="balance-value">{{ this.availableBalance }}</span>{{ `
                                            ${getProtocolCurrency(this.deploymentToken.provider)}` }}</span>
        </div>
        <div class="balance-minimum">
          <span>{{ $t('manageToken.activateToken.deployer.minimum') }}</span>
          <span><span class="balance-value">{{ this.minimumBalance }}</span>{{ `
                                            ${getProtocolCurrency(this.deploymentToken.provider)}` }}</span>
        </div>
      </div>
    </div>
    <div class="testnet-alert">
      <span>{{ $t('manageToken.activateToken.deployer.testnetAlert1') }} <span class="deployer-wallet-alert">{{
        deployerWallet }}</span>
        <copyToClipboard :link="deployerWallet" />
      </span>
      <div v-if="this.faucetLink">
        <span>{{ $t('manageToken.activateToken.deployer.testnetAlert2') }}
          <a
            :href="this.faucetLink"
            target="_blank"
          >{{ this.faucetLink }}</a> {{ $t('manageToken.activateToken.deployer.testnetAlert3')
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import copyToClipboard from '@/components/common/copy-to-clipboard';
import warningIcon from '@/assets/img/warning-icon.svg';
import { i18n } from '@/i18n';
import { getDeployerWallet, getDeploymentGasFee, getDeploymentGasPrice } from '~/utilities/external-api/apis/blockchainGwApi';

export default {
    name: 'DeployerWallet',
    components: {
        copyToClipboard,
    },

    props: {},

    data() {
        return {
            deployerWallet: 'Loading...',
            warningIcon,
            minimumBalance: 0,
            availableBalance: 0,
            errorBalance: false,
            networkTxType: 'EIP1559',
            faucetLink: '',
            scannerLink: '',
        };
    },

    created() {
        this.setSubmitButtonDisabled(true);
        this.getNetworkInfo();
        this.getBalances();
    },

    computed: {
        ...mapState('configToken', ['deploymentToken']),

        ...mapGetters('configToken', ['getAvailableNetworks']),
    },

    methods: {
        ...mapActions('configToken', ['getDeploymentGasCostEstimationInGwei', 'setSubmitButtonDisabled']),
        ...mapMutations('global', ['CALL_ERROR_TOASTER']),

        showLinkToScanner(link) {
            return `${this.scannerLink}address/${link}`;
        },
        getProtocolCurrency(provider) {
            const matic = /polygon/;
            const avax = /avalanche/;
            const eth = /ethereum|ganache|goerli|besu|quorum|rinkeby/;
            const algo = /algorand/;

            switch (true) {
                case matic.test(provider):
                    return 'MATIC';
                case avax.test(provider):
                    return 'AVAX';
                case eth.test(provider):
                    return 'ETH';
                case algo.test(provider):
                    return 'ALGO';
                default:
                    return 'ETH';
            }
        },
        isTestnet(provider) {
            const regex = /test|quorum|goerli|kovan|rinkeby|sepolia|mumbai|polygon/; // TODO: Change this regex to the providers used in the current version (dev, RC, sandbox...)
            return regex.test(provider);
        },
        isEthereumPublicNetwork() {
            return this.deploymentToken.provider
                && (this.deploymentToken.provider.includes('ethereum') || this.deploymentToken.provider.includes('polygon'));
        },
        getWalletBalance() {
            getDeployerWallet(this.deploymentToken.provider).then(
                (response) => {
                    this.availableBalance = (response.data.balance / (10 ** 18)).toFixed(3);
                    this.deployerWallet = response.data.address;
                    if (this.errorBalance) {
                        this.setSubmitButtonDisabled(false);
                    } else {
                        this.setSubmitButtonDisabled(Number(this.availableBalance) < Number(this.minimumBalance));
                    }
                },
            )
                .catch(
                    (error) => {
                        this.printError(`${i18n.t('manageToken.activateToken.deployer.walletBalanceError')} ${error}`);
                        this.setSubmitButtonDisabled(false);
                    },
                );
        },
        getBalances() {
            if (this.networkTxType === 'EIP1559') {
                getDeploymentGasFee(this.deploymentToken.provider).then(
                    (deploymentGas) => {
                        const { maxFeePerGas, maxPriorityFeePerGas } = deploymentGas.data;

                        this.getDeploymentGasCostEstimationInGwei(this.deploymentToken.provider).then(
                            (deploymentGasEstimation) => {
                                const { estimatedDeploymentGas } = deploymentGasEstimation;
                                const gasPriceInWei = parseInt(maxFeePerGas.substring(2), 16) + parseInt(maxPriorityFeePerGas.substring(2), 16);
                                const gasAmount = (gasPriceInWei * estimatedDeploymentGas) / (10 ** 18);
                                this.minimumBalance = gasAmount.toFixed(3);

                                this.getWalletBalance();
                            },
                        ).catch((error) => {
                            this.printError(`${i18n.t('manageToken.activateToken.deployer.minimumGasError')} ${error}`);
                            this.getWalletBalance();
                            this.errorBalance = true;
                        });
                    },
                ).catch((error) => {
                    this.printError(`${i18n.t('manageToken.activateToken.deployer.minimumGasError')} ${error}`);
                    this.getWalletBalance();
                    this.errorBalance = true;
                });
            } else {
                getDeploymentGasPrice(this.deploymentToken.provider).then( // legacy
                    (deploymentGas) => {
                        const { gasPrice } = deploymentGas.data;
                        this.getDeploymentGasCostEstimationInGwei(this.deploymentToken.provider).then(
                            (deploymentGasEstimation) => {
                                const { estimatedDeploymentGas } = deploymentGasEstimation;
                                const gasAmount = (gasPrice * estimatedDeploymentGas) / (10 ** 18);
                                this.minimumBalance = gasAmount.toFixed(3);

                                this.getWalletBalance();
                            },
                        ).catch((error) => {
                            this.printError(`${i18n.t('manageToken.activateToken.deployer.minimumGasError')} ${error}`);
                            this.getWalletBalance();
                            this.errorBalance = true;
                        });
                    },
                )
                    .catch(
                        (error) => {
                            this.printError(`${i18n.t('manageToken.activateToken.deployer.minimumGasError')} ${error}`);
                            this.getWalletBalance();
                            this.errorBalance = true;
                        },
                    );
            }
        },

        printError(err) {
            this.$log.error(err);
            this.CALL_ERROR_TOASTER(err);
        },

        getNetworkInfo() {
            const availableNetworks = this.getAvailableNetworks;

            availableNetworks.forEach((network) => {
                if (network.id === this.deploymentToken.provider) {
                    this.faucetLink = network.faucetUrl;
                    this.scannerLink = network.explorerUrl;
                    this.networkTxType = network.networkTxType;
                }
            });
        },

    },
};
</script>
<style scoped>
.deployer-wallet-container {
    display: flex;
    flex-direction: column;
    display: flex;
    align-items: center;
    width: 90%;
    max-width: 720px;

    padding: 28px 40px;
    gap: 20px;

    background: #FFFFFF;
}

.warning-icon {
    width: 36.67px;
    height: 31.67px;

    filter: brightness(0) saturate(100%);
    filter: invert(44%) sepia(92%) saturate(5736%) hue-rotate(336deg) brightness(94%) contrast(110%);
}

.deployer-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;
    color: #0283A8;

}

.deployer-warning {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 12px;
    gap: 12px;

    text-align: center;

    background: #FFFFFF;
    border: 1px solid #FC233E;

    color: #000000;
}

.deployer-balance {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 12px 24px;
    gap: 30px;
    background: #F7F7F7;
    border-radius: 12px;
    width: 100%;
}

.balance-summary {
    display: flex;
    flex-direction: column;
    padding: 8px;

    background: #FFFFFF;
    border-radius: 8px;
    width: 40%;
    color: #000000;
}

.balance-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height, or 150% */

    color: #000000;
}

.balance-available,
.balance-minimum {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.testnet-alert {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    gap: 2px;

    background: #FFE9B1;
    border-radius: 5px;

    color: #000000;
}

.deployer-wallet-alert {
    display: inline-block;
    text-overflow: ellipsis;
    width: 8ch;
    overflow-x: hidden;
    vertical-align: bottom;
}

.balance-value {
    font-weight: 700;
}
</style>
