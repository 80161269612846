<template>
  <b-card
    :border-variant="headerBackground"
    :header-bg-variant="headerBackground"
    header-class="step-card-header"
    body-class="step-card-body"
    :header-text-variant="headerTextVariant"
    :title="stepTitle"
    class="step-card"
    :class="{ 'disabled': !isCardEnabled }"
    @click="changeStep"
  >
    <template v-slot:header>
      <div class="d-flex justify-content-between">
        <span>{{ $t('manageToken.step') }} {{ displayedIndex }}</span>
        <div>
          <span
            :class="{ 'card-selected': isCardSelected }"
            class="add-dot"
          /><span>{{ stepStatus }}</span>
        </div>
      </div>
    </template>
    <b-card-text>{{ stepDescription }}</b-card-text>
  </b-card>
</template>

<script>
export default {
  name: 'CpTokenStepCard',
  props: {
    isCardSelected: {
      type: Boolean,
      default: false,
    },
    stepIndex: {
      type: Number,
      required: true,
    },
    stepTitle: {
      type: String,
      default: '',
    },
    stepStatus: {
      type: String,
      default: '',
    },
    stepDescription: {
      type: String,
      default: '',
    },
    isCardEnabled: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    headerTextVariant() {
      return this.isCardSelected ? 'white' : 'dark';
    },
    headerBackground() {
      return this.isCardSelected ? 'primary' : 'light';
    },
    displayedIndex() {
      return this.stepIndex + 1;
    },
  },
  methods: {
    changeStep() {
      if (!this.isCardSelected && this.isCardEnabled) {
        this.$emit('change-step');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.step-card {
  cursor: pointer;
  flex-basis: 230px;
  flex-grow: 0.1;
  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}
.step-card-header {
  font-weight: bold;
  font-size: 12px;
  padding: 5px 10px 5px 16px;
}
.step-card-body {
  font-size: 14px;
  padding-left: 16px;
  padding-right: 10px;
  min-height: 121px;
  .card-title {
    font-size: 16px;
  }
}
.add-dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #627d8e;
  display: inline-block;
  vertical-align: middle;
  margin: 0 4px;
  &.card-selected {
    background-color: white;
  }
}
</style>
