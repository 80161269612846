<template>
  <div class="card-container">
    <cp-multi-sig-modal
      ref="cpMultiSigModal"
      @onOk="addNewMultiSig"
    />
    <cp-confirm-modal
      ref="cpConfirmModal"
      @onOk="submitInfo"
      @onCancel="$emit('step-submitted', false)"
    >
      {{ $t('manageToken.confirm') }}
    </cp-confirm-modal>
    <div
      v-if="loading"
      class="text-center my-2 minimum-height loader"
    >
      <b-row>
        <b-col class="text-center">
          <b-spinner class="align-middle" />
        </b-col>
      </b-row>
    </div>
    <b-card
      v-else
      class="card-container mb-3"
    >
      <CpMultiSigCard
        v-for="(wallet, index) in multiSigWallets"
        :id="wallet.id"
        :key="index"
        :title="wallet.walletName"
        :threshold="wallet.threshold"
        :owners="wallet.owners"
        :deployment-status="wallet.deploymentStatus"
        :address="wallet.walletAddress"
        @submitError="printError"
      />
      <b-card
        v-if="displayAddNewWalletButton"
        style="margin:10px;"
      >
        <div class="multisig-add">
          <cp-button
            size="lg"
            @click="addNewOwnership"
          >
            <i class="mr-1 ion ion-ios-add-circle-outline" />
            Add new MultiSig Wallet
          </cp-button>
        </div>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import CpButton from '@/components/common/standalone-components/cp-button';
import CpConfirmModal from '@/components/common/modals-components/confirm-modal';
import CpMultiSigModal from '@/pages/_idIssuer/configuration/fundraise-token/manage-token/components/manage-roles-components/multi-sig-modal';
import CpMultiSigCard from '../manage-roles-components/multi-sig-card';
import { i18n } from '@/i18n';

export default {
  name: 'CpManageRolesMultiSig',
  components: { CpMultiSigCard, CpMultiSigModal, CpButton, CpConfirmModal },
  props: {
    submitClicked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      multiSigWallets: [],
      multiSigHeaders: [
        { title: this.$t('manageToken.manageRoles.headers.walletAddress'), size: 8 },
        { title: this.$t('manageToken.manageRoles.headers.comment'), size: 4 },
      ],
    };
  },
  computed: {
    ...mapState('configToken', ['deploymentToken']),
    ...mapGetters('configToken', ['tokenDeployed', 'getMultiSigWallets']),
    displayAddNewWalletButton() {
      return !(this.deploymentToken.status === 'initial' && (this.getMultiSigWallets.length > 0 || this.multiSigWallets.length > 0));
    },
    fetchMultiSigWallets() {
      return this.getMultiSigWallets;
    },
  },
  watch: {
    submitClicked(value) {
      if (value) {
        if (!this.tokenDeployed) {
          this.submitInfo();
        } else {
          this.displayDeployModal();
        }
      }
    },
    fetchMultiSigWallets(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loading = true;
        this.clearPersistedData();
        this.loadPersistedData()
          .then(() => {
            this.loading = false;
          });
      }
    },
  },
  created() {
    this.loadPersistedData().then(() => {
      this.loading = false;
    });
  },
  methods: {
    ...mapActions('configToken', ['postDeploymentMultiSigWallets', 'getAllMultiSigWallets']),
    ...mapMutations('global', ['CALL_ERROR_TOASTER']),
    ...mapGetters('configToken', ['getRoles']),
    clearPersistedData() {
      this.multiSigWallets = [];
    },
    async loadPersistedData() {
      const multiSigWalletData = this.getMultiSigWallets.slice();
      multiSigWalletData.forEach((wallet) => {
        this.multiSigWallets.push({
          id: wallet.id,
          walletName: wallet.walletName,
          threshold: wallet.threshold,
          owners: wallet.owners,
          deploymentStatus: wallet.deploymentStatus,
          walletAddress: wallet.walletAddress,
        });
      });
    },
    displayDeployModal() {
      this.$refs.cpConfirmModal.show();
    },
    addNewOwnership() {
      this.$refs.cpMultiSigModal.show();
    },
    async addNewMultiSig({ name: walletName, threshold }) {
      const isWalletNameInUse = await this.checkForDuplicatedWalletName(walletName);

      if (!isWalletNameInUse) {
        const owners = [];

        for (let i = 0; i < threshold; i += 1) {
          owners.push({ address: '', comment: '' });
        }

        this.multiSigWallets.push({ walletName, threshold, owners });
      } else {
        this.printError(i18n.t('store.toaster.configToken.manageToken.duplicatedWalletName'));
      }
    },
    async checkForDuplicatedWalletName(walletName) {
      return this.multiSigWallets.some(item => item.walletName.trim().toUpperCase()
          === walletName.trim().toUpperCase());
    },
    submitInfo() {
      if (this.tokenDeployed) {
        this.loading = true;
        this.postDeploymentMultiSigWallets({ deploymentId: this.deploymentToken.id }).then(
          () => {
            this.$emit('step-submitted', { success: true });
          },
        ).catch(
          () => {
            this.$emit('step-submitted', { success: false });
          },
        ).finally(
          () => {
            this.getAllMultiSigWallets({ deploymentId: this.deploymentToken.id })
              .then(() => {
                this.clearPersistedData();
                this.loadPersistedData()
                  .then(() => {
                    this.loading = false;
                  });
              });
          },
        );
      } else if (this.getRoles().length !== 0) {
        this.$emit('step-submitted', { success: true });
      } else {
        this.printError(i18n.t('store.toaster.configToken.manageToken.emptyRoles'));
        this.$emit('step-submitted', { success: false });
      }
    },
    printError(err) {
      this.$log.error('Multisig Wallet Error:', err);
      this.CALL_ERROR_TOASTER(err);
    },
  },
};
</script>

<style scoped type="scss">
.card-container {
  min-height: 35rem;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
}
.spinner-border {
  width: 5rem;
  height: 5rem;
}
.card-body {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.multisig-add {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35rem;
  height: 35rem;
}
</style>
