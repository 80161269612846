<template>
  <cp-general-modal
    ref="multiSigModal"
    title="New MultiSig Wallet"
    size="md"
    @onOk="handleSubmit"
  >
    <cp-input
      v-model="item.name"
      name="walletName"
      label="Name"
      placeholder="Name"
      validate="required"
    />
    <cp-input
      v-model="item.threshold"
      name="threshold"
      label="Threshold"
      placeholder="Threshold"
      input-type="number"
      :html-props="{min:2,max:10}"
      :validate="`required|min_value:2|max_value:10`"
    />
  </cp-general-modal>
</template>
<script>
import CpInput from '@/components/common/standalone-components/inputs/cp-input';
import CpGeneralModal from '@/components/common/modals-components/general-modal';

export default {
  name: 'CpMultiSigModal',
  components: { CpInput, CpGeneralModal },
  data() {
    return {
      item: {
        threshold: 2,
      },
    };
  },
  methods: {
    show() {
      this.$refs.multiSigModal.show();
    },
    handleSubmit() {
      this.$emit('onOk', this.item);
      this.item = { threshold: 2 };
      this.$refs.multiSigModal.hide();
    },
  },
};
</script>

<style scoped>
</style>
