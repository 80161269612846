<template>
  <div>
    <CpWarning
      v-if="!editMode && !showSpinner"
    />
    <cp-input-container class="mb-5">
      <div class="row variable-input-row">
        <div class="col-md-1 d-flex">
          <label class="form-label">Compliance Type</label>
        </div>
        <div class="col-md-2 d-flex">
          <cp-select
            id="selectCompliance"
            v-model="deploymentComplianceType"
            :options="['regulated','partitioned','whitelisted']"
            :disabled="isTokenDeployed"
            @input="initializeConfiguration"
          />
        </div>
      </div>
    </cp-input-container>

    <div
      v-if="showSpinner"
      class="text-center my-2 minimum-height"
    >
      <div class="row align-items-center mb-2">
        <div class="col-md-12 text-center">
          <b-spinner class="align-middle" />
        </div>
      </div>
    </div>
    <cp-input-container
      v-if="!showSpinner"
      ref="cpInputContainer"
      v-model="complianceVariables"
    >
      <cp-confirm-modal
        ref="cpConfirmModal"
        @onOk="submit"
        @onCancel="$emit('step-submitted', false)"
      >
        {{ $t('manageToken.confirm') }}
      </cp-confirm-modal>
      <cp-tabular-headers :columns="headerItems" />
      <div
        v-for="rule in complianceVariables.variables"
        :key="rule.variable"
        class="row variable-input-row"
      >
        <div class="col-md-4 d-flex">
          <span class="var-name">{{ $t(`manageToken.complianceRules.variables.${rule.variable}`) }}</span>
        </div>
        <div class="col-md-2 py-2">
          <cp-select
            v-if="rule.type == 'boolean'"
            v-model="rule.value"
            :name="rule.variable"
            :options="booleanOptions"
            class="value-input"
            input-class="cp-input-override"
            :disabled="!editMode"
          />
          <cp-date-picker
            v-else-if="rule.type == 'date'"
            v-model="rule.value"
            :name="rule.variable"
            class="date-input"
            input-class="cp-input-override"
            calendar-on-right
            :disabled="!editMode"
          />
          <cp-input
            v-else
            v-model="rule.value"
            :name="rule.variable"
            :validate="'required|numeric'"
            class="value-input"
            input-class="cp-input-override"
            :is-readonly="!editMode"
          />
        </div>
        <div class="col-md-6 py-2">
          <cp-textarea
            v-model="rule.comment"
            :name="`${rule.variable}Comment`"
            :rows="2"
            input-class="cp-input-override"
            :is-readonly="!editMode"
          />
        </div>
      </div>
      <div
        v-if="isNotComplianceWhitelisted"
        class="row variable-input-row"
      >
        <div class="col-md-4 d-flex">
          <span class="var-name">{{ $t('manageToken.complianceRules.variables.countriesCompliance') }}</span>
        </div>
        <div class="col-md-8 pt-2">
          <cp-textarea
            v-model="countriesComplianceStatuses"
            :rows="4"
            :disabled="!editMode"
            validate="required|json"
            name="Countries Compliance Statuses"
          />
        </div>
      </div>
    </cp-input-container>
  </div>
</template>
<script>
import * as _ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import BigNumber from 'bignumber.js';
import { CpInput, CpSelect } from '~/components/common/standalone-components/inputs';
import CpDatePicker from '~/components/common/standalone-components/inputs/cp-date-picker';
import CpInputContainer from '~/components/common/cpInputContainer';
import CpTabularHeaders from '../common/tabular-headers';
import CpTextarea from '~/components/common/standalone-components/inputs/cp-textarea';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';
import CpWarning from '../common/warning';
import { deltaCountryComplianceChanges, sortComplianceRules, toTableData } from '../common/helpers';
import ComplianceManager from '../common/compliance-manager';
import { ComplianceType } from '../common/compliance';

export default {
  name: 'CpComplianceConfiguration',
  components: {
    CpInput,
    CpSelect,
    CpDatePicker,
    CpInputContainer,
    CpTabularHeaders,
    CpTextarea,
    CpConfirmModal,
    CpWarning,
  },
  props: {
    submitClicked: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      editMode: true,
      showSpinner: false,
      complianceType: ComplianceType.REGULATED,
      complianceVariables: {},
      countriesComplianceStatuses: '',
      booleanOptions: [
        { value: 'false', text: 'NO' },
        { value: 'true', text: 'YES' },
      ],
      headerItems: [{ title: this.$t('manageToken.complianceRules.headers.variable'), size: 4 },
        { title: this.$t('manageToken.complianceRules.headers.value'), size: 2 },
        { title: this.$t('manageToken.complianceRules.headers.comment'), size: 6 }],
    };
  },
  computed: {
    ...mapGetters('configToken', ['getTransactions', 'getComplianceConfiguration', 'hasCompliance', 'tokenDeployed']),
    ...mapState('configToken', ['deploymentToken']),
    deploymentComplianceType: {
      get() {
        return this.complianceType || ComplianceType.REGULATED;
      },
      set(complianceType) {
        this.complianceType = complianceType;
      },
    },
    isTokenDeployed() {
      return this.tokenDeployed;
    },
    isRolesEnabled() {
      return this.tokenDeployed || this.hasCompliance();
    },
    tokenDecimals() {
      return this.deploymentToken.decimals;
    },
    isNotComplianceWhitelisted() {
      return this.deploymentComplianceType !== ComplianceType.WHITELISTED;
    },
    deploymentComplianceTypeChange() {
      return this.deploymentComplianceType !== this.deploymentToken.complianceType;
    },
  },
  watch: {
    submitClicked(value) {
      if (value) {
        if (!this.tokenDeployed) {
          this.submit();
        } else {
          this.displayDeployModal();
        }
      }
    },
  },
  created() {
    this.deploymentComplianceType = this.deploymentToken.complianceType;
    this.initializeConfiguration();
  },
  methods: {
    ...mapActions('configToken', ['getDeploymentTransactions', 'getLatestPendingTransaction', 'addComplianceConfiguration', 'updateComplianceConfiguration', 'updateComplianceType']),

    async initializeConfiguration() {
      this.showSpinner = true;
      const strategy = ComplianceManager.getComplianceStrategy(this.deploymentComplianceType);

      Promise.resolve(this.initialize(strategy))
        .finally(() => {
          this.showSpinner = false;
        });
    },
    async initialize(strategy) {
      await this.getDeploymentTransactions(this.deploymentToken.id);
      const pendingTransaction = this.getLatestPendingTransaction();
      this.editMode = true;

      if (pendingTransaction.isLocked) {
        this.editMode = false;
        await this.setCompliance(pendingTransaction);
      } else if (this.deploymentComplianceTypeChange || !this.hasCompliance()) {
        const defaultCompliance = await this.getDefaultCompliance(strategy);
        await this.setCompliance(defaultCompliance);
      } else {
        await this.setCompliance(this.getComplianceConfiguration);
      }
    },
    async getDefaultCompliance(strategy) {
      const defaultComplianceRules = strategy.getDefaultCompliance();
      const defaultCountriesComplianceStatuses = strategy.getDefaultCountriesCompliance();

      return {
        complianceRules: defaultComplianceRules,
        countriesComplianceStatuses: defaultCountriesComplianceStatuses,
      };
    },
    async setCompliance({ complianceRules, countriesComplianceStatuses }) {
      this.complianceVariables.variables = await this.sanitizeInputs(sortComplianceRules(complianceRules));
      this.countriesComplianceStatuses = JSON.stringify(countriesComplianceStatuses, null, ' ');
    },
    sanitizeInputs(compliance) {
      if (this.deploymentComplianceType === ComplianceType.WHITELISTED) {
        const position = Object.keys(compliance).find(rule => compliance[rule].variable === 'authorizedSecurities');
        if (position) {
          // We alter here its value to remove the decimals for the UI
          const sanitizedValue = compliance[position];
          sanitizedValue.value = this.getSecuritiesHoldingsSanitizedValue(sanitizedValue.value);
          return [
            sanitizedValue,
          ];
        }

        return [];
      }

        Object.keys(compliance).forEach((rule) => {
          switch (compliance[rule].variable) {
            case 'forceFullTransfer':
            case 'worldWideForceFullTransfer':
            case 'forceAccredited':
            case 'forceAccreditedUS':
            case 'disallowBackDating':
              compliance[rule].value = compliance[rule].value.toString();
              compliance[rule].type = 'boolean';
              break;
            case 'blockFlowbackEndTime':
              compliance[rule].value = (compliance[rule].value !== 0) ? new Date(compliance[rule].value * 1000).toISOString() : '';
              compliance[rule].type = 'date';
              break;
            case 'minimumHoldingsPerInvestor':
            case 'maximumHoldingsPerInvestor':
            case 'authorizedSecurities':
              compliance[rule].value = this.getSecuritiesHoldingsSanitizedValue(compliance[rule].value);
              break;
            default:
              break;
          }
        });

      return compliance;
    },
    displayDeployModal() {
      this.$refs.cpConfirmModal.show();
    },
    getSecuritiesHoldingsValue(value) {
      return (value !== 0 && value !== '') ? (new BigNumber(value).multipliedBy(new BigNumber(10).pow(this.tokenDecimals))).toString(10) : value;
    },
    getSecuritiesHoldingsSanitizedValue(value) {
      if (value !== 0 && value !== '') {
        return (new BigNumber(value).div((new BigNumber(10).pow(this.tokenDecimals)))).toString(10);
      }
      return value;
    },
    async submit() {
      const deploymentId = this.deploymentToken.id;
      const complianceConfigParams = {
        complianceType: '',
        complianceRules: {},
        // countriesComplianceStatuses: [],
      };

      complianceConfigParams.complianceType = this.deploymentComplianceType;

      if (!this.tokenDeployed && this.deploymentComplianceTypeChange) {
        await this.submitUpdateComplianceType(deploymentId, complianceConfigParams);
      }

      const isValid = await this.$refs.cpInputContainer.validateForm();
      if (!isValid || !deploymentId || this.editMode === false) {
        return this.submitFail();
      }

      this.showSpinner = true;
      this.editMode = false;

      if (!this.tokenDeployed) {
        const complianceRules = Object.keys(this.complianceVariables.variables).reduce(
          (acc, value) => {
            const rule = this.complianceVariables.variables[value];
            return { ...acc, [rule.variable]: { value: rule.value, comment: rule.comment } };
          },
          {},
        );

        if (this.deploymentComplianceType !== ComplianceType.WHITELISTED) {
          complianceRules.blockFlowbackEndTime.value = (complianceRules.blockFlowbackEndTime.value !== 0
              && complianceRules.blockFlowbackEndTime.value !== '')
            ? Math.floor(new Date(complianceRules.blockFlowbackEndTime.value).getTime() / 1000) : 0;
          complianceRules.minimumHoldingsPerInvestor.value = this.getSecuritiesHoldingsValue(complianceRules.minimumHoldingsPerInvestor.value);
          complianceRules.maximumHoldingsPerInvestor.value = this.getSecuritiesHoldingsValue(complianceRules.maximumHoldingsPerInvestor.value);
        }

        complianceRules.authorizedSecurities.value = this.getSecuritiesHoldingsValue(complianceRules.authorizedSecurities.value);
        complianceConfigParams.countriesComplianceStatuses = JSON.parse(this.countriesComplianceStatuses);
        complianceConfigParams.complianceRules = complianceRules;
      } else {
        const ruleChanges = toTableData(this.complianceVariables.variables);
        const currentRules = toTableData(this.getComplianceConfiguration.complianceRules);
        const currentCountries = this.getComplianceConfiguration.countriesComplianceStatuses;

        complianceConfigParams.complianceRules = Object.keys(this.complianceVariables.variables).reduce((prev, key) => {
          switch (ruleChanges[key].variable) {
            case 'forceFullTransfer':
            case 'worldWideForceFullTransfer':
            case 'forceAccredited':
            case 'forceAccreditedUS':
            case 'disallowBackDating':
              ruleChanges[key].value = JSON.parse(ruleChanges[key].value);
              break;
            case 'blockFlowbackEndTime':
              ruleChanges[key].value = (ruleChanges[key].value !== 0 && ruleChanges[key].value !== '') ? Math.floor(new Date(ruleChanges[key].value).getTime() / 1000) : 0;
              break;
            case 'minimumHoldingsPerInvestor':
            case 'maximumHoldingsPerInvestor':
            case 'authorizedSecurities':
              ruleChanges[key].value = this.getSecuritiesHoldingsValue(ruleChanges[key].value);
              break;
            default:
              break;
          }

          const changedValue = currentRules[key].value !== ruleChanges[key].value;
          let changedComment = false;

          if (currentRules[key].comment && currentRules[key].comment !== '') {
            changedComment = currentRules[key].comment !== ruleChanges[key].comment;
          }

          if (changedValue || changedComment) {
            prev = {
              ...prev,
              [ruleChanges[key].variable]: { value: ruleChanges[key].value, comment: ruleChanges[key].comment || '' },
            };
          }
          return prev;
        }, {});

          const newCountriesCompliance = JSON.parse(this.countriesComplianceStatuses);
          const newComplianceCountryNames = newCountriesCompliance.map(c => c.countryName);
          const missing = _(currentCountries)
              .map((c) => {
                if (newComplianceCountryNames.indexOf(c.countryName) === -1) {
                  return c;
                }
                return null;
              })
              .without(null)
              .map(c => ({ ...c, complianceStatus: 'none' }))
              .value();

          const changes = deltaCountryComplianceChanges(currentCountries, newCountriesCompliance);

          complianceConfigParams.countriesComplianceStatuses = _.concat(changes, missing);
      }

      if (Object.keys(complianceConfigParams.complianceRules).length > 0 || complianceConfigParams.countriesComplianceStatuses.length > 0) {
        const addOrUpdateCompliance = this.tokenDeployed ? 'updateComplianceConfiguration' : 'addComplianceConfiguration';
        await this.submitAddOrUpdateCompliance(addOrUpdateCompliance, deploymentId, complianceConfigParams);
        return;
      }

      await this.submitFail();
    },
    async submitFail() {
      this.$emit('step-submitted', { success: false });
      await this.initializeConfiguration();
    },
    submitUpdateComplianceType(deploymentId, complianceConfigParams) {
      this.updateComplianceType({ deploymentId, complianceConfigParams });
    },
    submitAddOrUpdateCompliance(addOrUpdateCompliance, deploymentId, complianceConfigParams) {
      this[addOrUpdateCompliance]({ deploymentId, complianceConfigParams }).then(
        () => {
          this.$emit('step-submitted', { success: true });
        },
      ).catch(
        () => {
          this.$emit('step-submitted', { success: false });
        },
      ).finally(
        () => {
          this.initializeConfiguration();
        },
      );
    },
  },
};
</script>

<style>
.cp-input-override {
  margin-bottom: 0px;
  margin-top: 0px;
}
</style>

<style lang="scss" scoped>
.variable-input-row {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 12px;
  padding-right: 12px;
  &:nth-child(even) {
    background-color: #f9f9f9;
    border-top: 1px solid;
    border-color: #e5e5e5;
  }
}

.header-row {
  padding-left: 24px;
  padding-right: 24px;
}

.value-input {
  max-width: 118px;
}

.date-input {
  margin-left: -42px;
  max-width: 160px;
}

.var-name {
  align-self: center;
  margin-bottom: 0;
}

.form-head-text {
  font-weight: 500;
}

.minimum-height {
  padding-top: 50px;
  min-height: calc(200px - 37px);
}
</style>
